import React, { useContext, useState } from "react"
import { StyleSheet, View } from "react-native"
import Modal from "modal-enhanced-react-native-web"
import * as Yup from "yup"
import Toast from "react-native-tiny-toast"

import useModal from "../hooks/useModal"
import AuthContext from "../auth/context"
import colors from "../config/colors"
import modals from "../config/modals"
import { fetcher, getModalDimensions } from "../config/Globals"
import { AppForm, AppFormField, SubmitButton } from "../components/forms"
import CloseButton from "../components/CloseButton"
import Text from "../components/Text"

const ContactScreen = () => {
  const { user, isSmallScreen } = useContext(AuthContext)
  const { isOpen, toggleModal } = useModal()
  const { width, height, controlwidth } = getModalDimensions(isSmallScreen)
  const [error, setError] = useState(false)
  const validationSchema = Yup.object().shape({
    name: Yup.string().required().label("Name"),
    email: Yup.string()
      .required()
      .email("Please enter a valid email")
      .label("Email"),
    comments: Yup.string().required().label("Comments")
  })

  const handleSubmit = async values => {
    const response = await fetcher("/contact-us", {
      method: "post",
      data: values
    })

    if (response.statusText === "OK") {
      handleToggle()
      return Toast.show("Comments sent!", {
        containerStyle: {
          borderRadius: 30,
          backgroundColor: colors.wseorange,
          width: 250
        },
        textStyle: {
          fontWeight: "bold",
          fontSize: 16
        },
        position: 0
      })
    }

    setError(true)
  }

  const handleToggle = () => {
    toggleModal(modals.CONTACT_US)
  }

  const styles = StyleSheet.create({
    closeButton: {
      position: "absolute",
      top: 0,
      right: 0
    },
    errorText: {
      textAlign: "center",
      fontSize: 12,
      color: colors.error
    },
    formField: {
      height: isSmallScreen ? 30 : 40,
      width: controlwidth,
      marginBottom: isSmallScreen ? 40 : 30
    },
    modal: {
      backgroundColor: colors.white,
      width,
      minWidth: 300,
      height: isSmallScreen ? height - 40 : height / 2,
      minHeight: 500,
      alignItems: "center",
      justifyContent: "center",
      alignSelf: "center",
      borderRadius: 10,
      shadowOffset: { width: 1, height: 1 },
      shadowOpacity: 0.5,
      shadowColor: colors.medium,
      shadowRadius: 2
    },
    multilineInput: {
      height: 80
    },
    submitButton: {
      width: "50%"
    },
    title: {
      fontSize: 20,
      fontWeight: "bold",
      paddingBottom: 16
    }
  })

  return (
    <Modal
      isVisible={isOpen(modals.CONTACT_US)}
      onBackdropPress={handleToggle}
      animationIn="zoomIn"
      animationOut="zoomOut"
    >
      <View style={styles.modal}>
        <Text style={styles.title}>Contact Us</Text>
        <AppForm
          initialValues={{
            name: user?.fullName ?? "",
            email: user?.email ?? "",
            comments: ""
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <AppFormField
            name="name"
            placeholder="Name"
            style={styles.formField}
            autoCapitalize="none"
            autoCorrect={false}
          />
          <AppFormField
            name="email"
            placeholder="Email"
            style={styles.formField}
            textContentType="emailAddress"
            autoCapitalize="none"
            autoCorrect={false}
          />
          <AppFormField
            name="comments"
            placeholder="Comments"
            multiline
            numberOfLines={3}
            style={[styles.formField, styles.multilineInput]}
            autoCapitalize="none"
            autoCorrect={false}
          />
          <SubmitButton title="Submit" styleButton={styles.submitButton} />
          {error && (
            <Text style={styles.errorText}>
              There was an error processing your request. Please try again.
            </Text>
          )}
        </AppForm>
        <CloseButton style={styles.closeButton} onPress={handleToggle} />
      </View>
    </Modal>
  )
}

export default ContactScreen
