import React from "react"
import { StyleSheet, View } from "react-native"

function MenuSeparator() {
  return <View style={styles.separator} />
}

const styles = StyleSheet.create({
  separator: {
    width: "100%",
    margin: 10
  }
})

export default MenuSeparator
