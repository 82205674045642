import React, { Fragment, useContext } from "react"
import { StyleSheet, View } from "react-native"

import AuthContext from "../auth/context"
import colors from "../config/colors"
import SEO from "../components/SEO"
import Text from "../components/Text"

const AboutScreen = () => {
  const { isSmallScreen } = useContext(AuthContext)

  const styles = StyleSheet.create({
    container: {
      backgroundColor: colors.white,
      flex: 1,
      width: isSmallScreen ? "100%" : "50%",
      minWidth: !isSmallScreen && 500,
      alignSelf: "center",
      paddingTop: 50
    },
    content: {
      fontSize: 16,
      margin: 8,
      lineHeight: 25
    },
    title: {
      backgroundColor: colors.light,
      textAlign: "center",
      padding: 8,
      fontSize: 24,
      fontWeight: "bold"
    }
  })

  return (
    <Fragment>
      <SEO title="About Us" />
      <View style={styles.container}>
        <Text style={styles.title}>About West Side Eats</Text>
        <Text style={styles.content}>
          WestSideEats arose out of a simple idea: to help local restaurants get
          through a difficult time. If you ordered online during the pandemic,
          you probably did so believing you were supporting your favorite
          eateries. We did too, but as we looked into the situation, here's what
          we found: Thirty percent of dollars spent by the customer never made
          it to the actual restaurant.
        </Text>
        <Text style={styles.content}>
          The backstory: When restaurants had to close their doors to in-person
          dining in March 2020, delivery became crucial to their survival. Third
          party apps offered an easy way for customers to place orders and
          restaurants to receive them. But here's the thing: these companies
          charge impossibly high fees, so exorbitant they hurt our local
          restaurants. Third party apps do not develop menus, cook meals,
          interact with guests, or deliver the food. They're simply an online
          platform to order meals.
        </Text>
        <Text style={styles.content}>
          The problem: The actual labor of creating and delivering these meals
          falls on restaurant staff ... and contract delivery bikers ... and
          drivers. Yet third party apps take up to 30% of any money made by
          restaurants, and sometimes more.
        </Text>
        <Text style={styles.content}>
          Customers and friends who placed orders to support their local
          restaurants were shocked to learn this. Every restaurant will tell
          you: it is impossible to make money from a delivery business with 30%
          taken off the top of every order.
        </Text>
        <Text style={styles.content}>
          Yet what could they do? Local restaurants are busy scrambling to stay
          in business. They don't have the time, energy, or resources to
          advocate for themselves.
        </Text>
        <Text style={styles.content}>
          The solution: We decided to do something about it. And we did what all
          middle-aged adults do -- we turned to young adults to help us navigate
          the technology. We tapped into a diverse group of high school and
          college-age students studying computer science, programming, business,
          and social media marketing. Working together -- yet remotely -- we met
          every week during the pandemic to create and fine-tune this new
          restaurant ordering service: WestSideEats.
        </Text>
        <Text style={styles.content}>
          The purpose: We believe strongly in local communities. So we started
          within our own neighborhood and will donate a percent of all sales to
          local non-profit organizations who've done heroic work in the past 18
          months and will continue to make an impact in our community. We're all
          in this together. This is our push to try and save the wonderful
          restaurants that make New York the unique culinary melting pot that we
          all love.
        </Text>
        <Text style={styles.content}>
          The future: We're just getting off the ground. More restaurants are
          being added, with still more to come.
        </Text>
        <Text style={styles.content}>
          Your role: By signing up with and using WestSideEats, you are part of
          the solution and the future -- one where you can enjoy delivery from
          restaurants you know and support, trusting that they're fairly
          compensated, able to stay open, and even thrive.
        </Text>
      </View>
    </Fragment>
  )
}

export default AboutScreen
