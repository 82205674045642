import React from "react"
import { View, Dimensions, Platform, StyleSheet } from "react-native"
import * as Yup from "yup"

import { AppForm, AppFormField, SubmitButton } from "../components/forms"
import colors from "../config/colors"
import Text from "../components/Text"
import appStorage from "../utility/cache"
import {
  getLocationInfo,
  getModalDimensions,
  makeServerAddress
} from "../config/Globals"
import AuthContext from "../auth/context"
import Modal from "modal-enhanced-react-native-web"
import ConfirmCodeScreen from "./ConfirmCodeScreen"
import { parse } from "date-fns"

function RegistrationScreen({
  navigation,
  registrationModalVisible,
  setRegistrationModalVisible
}) {
  const [confirmCodeVisible, setConfirmCodeVisible] = React.useState(false)
  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")
  const regExp = /\b\d{10}\b/
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required().label("First Name"),
    lastName: Yup.string().required().label("Last Name"),
    email: Yup.string()
      .required()
      .email("Please enter a valid email")
      .label("Email"),
    password: Yup.string().required().min(8).label("Password"),
    confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref("password"), null], "Passwords don't match.")
      .label("Confirm Password"),
    phoneNumber: Yup.string()
      .required()
      .matches(regExp, { message: "Must be exactly 10 numbers" })
      .label("Phone Number")
  })
  const { isSmallScreen } = React.useContext(AuthContext)
  const { width, height, controlwidth } = getModalDimensions(isSmallScreen)
  const createAccount = async values => {
    try {
      const locationInfo = getLocationInfo()
      const response = await fetch(makeServerAddress("/user"), {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          password: values.password,
          phoneNumber: values.phoneNumber,
          address:
            locationInfo != null
              ? locationInfo.address
              : { address: "address" },
          location:
            locationInfo != null
              ? locationInfo.coordinates
              : { location: "location" },
          locationParts:
            locationInfo != null
              ? locationInfo.location
              : { locationParts: "locationParts" }
        })
      })
      return response.status === 500 ? true : false
    } catch (err) {
      console.error(err.message)
    }
  }

  const styles = StyleSheet.create({
    formField: {
      height: isSmallScreen ? 30 : 50,
      width: controlwidth,
      marginBottom: isSmallScreen ? 40 : 30
    }
  })

  return (
    <>
      <Modal
        isVisible={registrationModalVisible}
        onBackdropPress={() => setRegistrationModalVisible(false)}
        animationIn="zoomIn"
        animationOut="zoomOut"
      >
        <View
          style={{
            width: width,
            height: isSmallScreen ? height - 40 : height / 2,
            minHeight: 650,
            minWidth: 300,
            alignSelf: "center",
            justifyContent: "center",
            backgroundColor: colors.white,
            shadowOffset: { width: 1, height: 1 },
            shadowOpacity: 0.5,
            shadowColor: colors.medium,
            shadowRadius: 2,
            borderRadius: 10
          }}
        >
          <AppForm
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              password: "",
              confirmPassword: "",
              phoneNumber: ""
            }}
            onSubmit={async (values, { setErrors }) => {
              const emailAlreadyInUse = await createAccount(values)
              setEmail(values.email)
              setPassword(values.password)
              if (!emailAlreadyInUse) {
                setRegistrationModalVisible(false)
                setConfirmCodeVisible(true)
              } else {
                setErrors({ email: "Email already in use" })
              }
            }}
            // validate={(values, props) => {
            //   const errors = {}

            //   if (values.email === "avikwal123") errors.email = "Required"

            //   console.log("validate")

            //   if (myFunction()) errors.vcode = ""

            //   return errors
            // }}
            validationSchema={validationSchema}
          >
            <View style={{ alignItems: "center" }}>
              <Text
                style={{
                  fontWeight: "bold",
                  fontFamily: Platform.OS === "android" ? "Roboto" : "Arial",
                  fontSize: isSmallScreen ? 18 : 22,
                  paddingBottom: "5%"
                }}
              >
                Create your account
              </Text>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <AppFormField
                  name="firstName"
                  placeholder="First Name"
                  style={[
                    styles.formField,
                    {
                      marginRight: 5,
                      width: controlwidth * 0.4
                    }
                  ]}
                  autoCapitalize="none"
                  autoCorrect={false}
                />
                <AppFormField
                  name="lastName"
                  placeholder="Last Name"
                  style={[
                    styles.formField,
                    {
                      width: controlwidth * 0.6
                    }
                  ]}
                  autoCapitalize="none"
                  autoCorrect={false}
                />
              </View>
              <AppFormField
                name="email"
                placeholder="Email"
                style={styles.formField}
                textContentType="emailAddress"
                autoCapitalize="none"
                autoCorrect={false}
              />
              <AppFormField
                name="password"
                placeholder="Password"
                style={styles.formField}
                autoCapitalize="none"
                autoCorrect={false}
                secureTextEntry
              />
              <AppFormField
                name="confirmPassword"
                placeholder="Confirm Password"
                style={styles.formField}
                autoCapitalize="none"
                autoCorrect={false}
                secureTextEntry
              />
              <AppFormField
                name="phoneNumber"
                placeholder="Phone Number"
                style={styles.formField}
                autoCapitalize="none"
                autoCorrect={false}
              />
              <SubmitButton
                title="Register"
                styleButton={{
                  backgroundColor: colors.medium,
                  width: controlwidth,
                  marginTop: 10
                }}
              />
            </View>
          </AppForm>
        </View>
      </Modal>
      <ConfirmCodeScreen
        navigation={navigation}
        confirmCodeVisible={confirmCodeVisible}
        setConfirmCodeVisible={setConfirmCodeVisible}
        email={email}
        password={password}
      />
    </>
  )
}

export default RegistrationScreen
