import React, { useContext } from "react"
import { StyleSheet, View } from "react-native"
import Modal from "modal-enhanced-react-native-web"

import useModal from "../hooks/useModal"
import AuthContext from "../auth/context"
import colors from "../config/colors"
import { getModalDimensions } from "../config/Globals"
import CloseButton from "../components/CloseButton"

const ModalLayout = ({ name, children, ...props }) => {
  const { toggleModal, isOpen } = useModal()
  const { isSmallScreen } = useContext(AuthContext)
  const { width, height } = getModalDimensions(isSmallScreen)

  const handleToggle = () => {
    toggleModal(name)
  }

  const styles = StyleSheet.create({
    closeButton: {
      position: "absolute",
      top: 0,
      right: 0
    },
    modal: {
      backgroundColor: colors.white,
      width,
      minWidth: 300,
      height: isSmallScreen ? height - 40 : height / 2,
      minHeight: 500,
      alignItems: "center",
      justifyContent: "center",
      alignSelf: "center",
      borderRadius: 10,
      shadowOffset: { width: 1, height: 1 },
      shadowOpacity: 0.5,
      shadowColor: colors.medium,
      shadowRadius: 2
    }
  })

  return (
    <Modal
      isVisible={isOpen(name)}
      onBackdropPress={handleToggle}
      animationIn="zoomIn"
      animationOut="zoomOut"
      {...props}
    >
      <View style={styles.modal}>
        {children}
        <CloseButton style={styles.closeButton} onPress={handleToggle} />
      </View>
    </Modal>
  )
}

export default ModalLayout
