import React, { useState } from "react"
import {
  View,
  StyleSheet,
  TouchableWithoutFeedback,
  Keyboard,
  Alert,
  Platform,
  Dimensions
} from "react-native"

import TextInput from "../components/TextInput"
import Button from "../components/Button"
import Text from "../components/Text"
import { getModalDimensions, makeServerAddress } from "../config/Globals"
import appStorage from "../utility/cache"
import JwtDecode from "jwt-decode"
import Modal from "modal-enhanced-react-native-web"
import AuthContext from "../auth/context"
import colors from "../config/colors"

function ConfirmCodeScreen({
  navigation,
  confirmCodeVisible,
  setConfirmCodeVisible,
  email,
  password
}) {
  const { user, setUser, isSmallScreen } = React.useContext(AuthContext)
  const { width, height, controlwidth } = getModalDimensions(isSmallScreen)

  const [validationCode, setValidationCode] = React.useState("")

  const validateCode = async () => {
    try {
      const response = await fetch(makeServerAddress("/user/validate"), {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: email,
          password: password,
          vcode: validationCode
        })
      })

      const jsonData = await response.json()
      if (response.status == 200) {
        const tokenuser = JwtDecode(jsonData.token)
        setUser(tokenuser)
        await appStorage.store("token", jsonData.token)
        setConfirmCodeVisible(false)
        const locationInfo = getLocationInfo()
        if (
          typeof tokenuser.restaurantId !== "undefined" &&
          tokenuser.restaurantId.length > 0
        )
          navigation.navigate("RestaurantOrders")
        else if (locationInfo != null) navigation.navigate("Home")
        else navigation.navigate("Opening")
      } else {
        Alert.alert("Error", "Invalid login entry. Please try again.", [
          { text: "Okay", style: "cancel" }
        ])
      }
    } catch (err) {
      console.error(err.message)
    }
  }

  const resendCode = async () => {
    try {
      const response = await fetch(makeServerAddress("/user/sendmail"), {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: email
        })
      })
      if (response.status == 200) {
        Alert.alert("Code Sent", "Code successfully sent to your email", [
          { text: "Okay", style: "cancel" }
        ])
      } else {
        Alert.alert("Error", "Invalid login entry. Please try again.", [
          { text: "Okay", style: "cancel" }
        ])
      }
    } catch (err) {
      console.error(err.message)
    }
  }

  const styles = StyleSheet.create({
    screen: {
      flex: 1,
      backgroundColor: colors.light
    },
    container: {
      padding: Platform.OS === "web" ? 0 : 10,
      alignItems: "center"
    },
    input: {
      padding: 30
    },
    buttonText: {
      fontSize: 22
    },
    buttonContainer: {
      width: controlwidth,
      height: isSmallScreen ? 40 : 50,
      marginVertical: 5
    },
    loginText: {
      fontSize: 30,
      textAlign: "center"
    },
    loginTextContainer: {
      paddingBottom: 50,
      paddingTop: 50
    },
    iconButton: {
      height: isSmallScreen ? 40 : 50,
      width: controlwidth
    }
  })

  return (
    <Modal
      isVisible={confirmCodeVisible}
      onBackdropPress={() => setConfirmCodeVisible(false)}
      animationIn="zoomIn"
      animationOut="zoomOut"
    >
      <View
        style={{
          width: width,
          height: isSmallScreen ? height - 40 : height / 2,
          minHeight: 500,
          minWidth: 300,
          alignSelf: "center",
          justifyContent: "center",
          backgroundColor: colors.white,
          shadowOffset: { width: 1, height: 1 },
          shadowOpacity: 0.5,
          shadowColor: colors.medium,
          shadowRadius: 2,
          borderRadius: 10
        }}
      >
        <View style={{ alignItems: "center" }}>
          <Text
            style={{
              fontWeight: "bold",
              // fontFamily: Platform.OS === "android" ? "Roboto" : "Arial",
              fontSize: isSmallScreen ? 18 : 22,
              paddingBottom: isSmallScreen ? 15 : "5%"
            }}
          >
            Verify Email Address
          </Text>
          <Text
            style={{
              // fontFamily: Platform.OS === "android" ? "Roboto" : "Arial",
              fontSize: isSmallScreen ? 14 : 18,
              paddingBottom: isSmallScreen ? 15 : "5%",
              textAlign: "center"
            }}
          >
            A validation code has been sent to your email address and phone
            number. Enter the code below.
          </Text>
        </View>
        <View style={{ alignItems: "center" }}>
          <TextInput
            label="Verify Code"
            style={{
              height: isSmallScreen ? 25 : 20,
              width: controlwidth,
              marginBottom: 50
            }}
            value={validationCode}
            onChangeText={value => setValidationCode(value)}
          />
          <View style={styles.container}>
            <Button
              title="Verify Code"
              styleButton={styles.buttonContainer}
              styleText={styles.buttonText}
              onPress={async () => await validateCode()}
            />
          </View>
          <View style={styles.container}>
            <Button
              title="Resend Code"
              styleButton={styles.buttonContainer}
              styleText={styles.buttonText}
              onPress={async () => await resendCode()}
            />
          </View>
        </View>
      </View>
    </Modal>
  )
}

export default ConfirmCodeScreen
