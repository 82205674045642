import React, { Fragment } from "react"
import { View, StyleSheet, ImageBackground, Image, Text } from "react-native"
import { GooglePlacesAutocomplete } from "react-native-google-places-autocomplete"

import AuthContext from "../auth/context"
import { makeFileServerAddress, updateLocation, getLocalName, 
  getLocalLogo, getLocationCoordinates, getSampleAddress, getLocalBackground } from "../config/Globals"
import { google as googleKey } from "../auth/keys.json"
import colors from "../config/colors"
import Button from "../components/Button"
import Footer from "../components/Footer"
import LoginScreen from "./LoginScreen"
import RegistrationScreen from "./RegistrationScreen"
import SEO from "../components/SEO"
import Slogan from "../components/Slogan"
import { Dimensions } from "react-native"

function OpeningScreen({ navigation }) {
  const { isSmallScreen, user, setCoordinates } = React.useContext(AuthContext)
  const [loginModalVisible, setLoginModalVisible] = React.useState(false)
  const [registrationModalVisible, setRegistrationModalVisible] =
    React.useState(false)

  const styles = StyleSheet.create({
    background: {
      flex: 1
    },
    logo: {
      width: isSmallScreen ? 100 : 40,
      height: isSmallScreen ? 50 : 40
    }
  })

  return (
    <Fragment>
      <SEO title="Opening" />
      <View style={{ flex: 1 }}>
        <ImageBackground
          source={{ uri: makeFileServerAddress(getLocalBackground(), "8080") }}
          style={styles.background}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start"
              // marginBottom: "5%"
            }}
          >
            <View style={{ flexDirection: "row", marginTop: 10 }}>
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "15%"
                }}
              >
                <Image
                  source={{
                    uri: makeFileServerAddress(
                      getLocalLogo(isSmallScreen)
                    )
                  }}
                  style={styles.logo}
                />
              </View>

              {!isSmallScreen && (
                <View style={{ marginLeft: 30 }}>
                  <Text
                    style={{ fontSize: 24, fontWeight: "bold", color: "white" }}
                  >
                    {getLocalName()}
                  </Text>
                </View>
              )}
            </View>
            {!user && (
              <View
                style={{
                  flexDirection: "row",
                  marginTop: !isSmallScreen && 10
                }}
              >
                <Button
                  title="Login"
                  color="white"
                  styleButton={{
                    width: isSmallScreen ? 60 : 90,
                    height: 30,
                    marginRight: 7
                  }}
                  styleText={{ color: colors.black }}
                  onPress={() => setLoginModalVisible(true)}
                  isSmallScreen={isSmallScreen}
                />
                <Button
                  title="Register"
                  color="black"
                  styleButton={{
                    width: isSmallScreen ? 70 : 100,
                    height: 30,
                    marginRight: 10
                  }}
                  onPress={() => setRegistrationModalVisible(true)}
                  isSmallScreen={isSmallScreen}
                />
              </View>
            )}
            {user && (
              <View
                style={{
                  marginRight: isSmallScreen ? 5 : 25,
                  backgroundColor: colors.black,
                  borderRadius: 5,
                  padding: 3,
                  marginVertical: 5
                }}
              >
                <Text
                  style={{
                    fontSize: isSmallScreen ? 16 : 18,
                    fontWeight: "bold",
                    color: colors.white
                  }}
                >
                  Welcome, {user.name}
                </Text>
              </View>
            )}
          </View>

          <View
            style={{
              alignSelf: "center",
              marginTop:
                Dimensions.get("window").height * (isSmallScreen ? 0.25 : 0.25)
            }}
          >
            <Slogan type="opening" />
          </View>

          <View style={{ marginTop: 15 }}>
            <GooglePlacesAutocomplete
              styles={{
                description: {
                  color: colors.black
                },
                listView: {
                  width: isSmallScreen ? 300 : 500,
                  marginTop: 5,
                  borderRadius: 7,
                  padding: 10,
                  backgroundColor: colors.white,
                  alignSelf: "center",
                  top: -250
                },
                textInput: {
                  backgroundColor: colors.white,
                  padding: 10,
                  borderWidth: 2,
                  borderRadius: 3
                },
                separator: {
                  marginVertical: 10,
                  borderWidth: 1,
                  borderColor: colors.white
                },
                textInputContainer: {
                  alignSelf: "center",
                  width: isSmallScreen ? 300 : 500
                },
                predefinedPlacesDescription: {
                  color: colors.skyblue
                }
              }}
              placeholder={getSampleAddress()}
              placeholderTextColor={colors.medium}
              fetchDetails
              GooglePlacesSearchQuery={{}}
              suppressDefaultStyles
              enablePoweredByContainer
              onPress={async (data, details) => {
                if (details.geometry.location) {
                  await updateLocation(data, details, user)
                  setCoordinates(details.geometry.location)
                  navigation.push("DeliveryOrPickup")
                }
              }}
              query={{
                key: googleKey,
                language: "en",
                location: getLocationCoordinates(),
                radius: "80000",
                strictbounds: "true"
              }}
              requestUrl={{
                url: "https://api.westsideeats.com:8080/https://maps.googleapis.com/maps/api",
                useOnPlatform: "web"
              }}
            />
          </View>
          <Footer style={{ bottom: 0 }} />
        </ImageBackground>
      </View>
      <RegistrationScreen
        navigation={navigation}
        registrationModalVisible={registrationModalVisible}
        setRegistrationModalVisible={setRegistrationModalVisible}
      />
      <LoginScreen
        navigation={navigation}
        loginModalVisible={loginModalVisible}
        setLoginModalVisible={setLoginModalVisible}
      />
    </Fragment>
  )
}

export default OpeningScreen
