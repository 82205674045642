import React from "react"
import { View, Text, StyleSheet } from "react-native"

import LoginInput from "../forms/LoginInput"

const GetCheckoutInfo = props => {
  return (
    <View style={styles.info}>
      <View style={{ paddingRight: 15 }}>
        <Text style={styles.text}>{props.children}</Text>
      </View>
      <LoginInput
        placeholder={props.placeholder}
        style={{ ...styles.input, ...props.style }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  text: {
    fontSize: 18
  },
  input: {
    width: "60%",
    height: 32,
    borderRadius: 0,
    borderWidth: 1,
    borderColor: "grey"
  },
  info: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end"
  }
})

export default GetCheckoutInfo
