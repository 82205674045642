import React from "react"
import { FlatList, View, StyleSheet, Platform, Text } from "react-native"
import Modal from "modal-enhanced-react-native-web"
import { getModalDimensions } from "../config/Globals"
import colors from "../config/colors"
import AuthContext from "../auth/context"

const RestaurantClosedScreen = ({
  closedModalVisible,
  setClosedModalVisible,
  hours
}) => {
  const { isSmallScreen } = React.useContext(AuthContext)
  const { width, height, controlwidth } = getModalDimensions(isSmallScreen)

  return (
    <>
      <Modal
        isVisible={closedModalVisible}
        onBackdropPress={() => setClosedModalVisible(false)}
        animationIn="zoomIn"
        animationOut="zoomOut"
      >
        <View
          style={{
            width: isSmallScreen ? 350 : 700,
            height: 500, // isSmallScreen ? height - 40 : height / 2,
            minHeight: 500,
            minWidth: 300,
            alignSelf: "center",
            justifyContent: "center",
            backgroundColor: colors.white,
            shadowOffset: { width: 1, height: 1 },
            shadowOpacity: 0.5,
            shadowColor: colors.medium,
            shadowRadius: 2,
            borderRadius: 10
          }}
        >
          <View style={{ alignItems: "center" }}>
            <Text
              style={{
                fontWeight: "bold",
                fontSize: isSmallScreen ? 18 : 22,
                padding: isSmallScreen ? 15 : "5%"
              }}
            >
              Sorry this restaurant is closed!
            </Text>
            <Text
              style={{
                fontWeight: "bold",
                fontSize: isSmallScreen ? 18 : 22,
                paddingRight: isSmallScreen ? 15 : "5%",
                paddingLeft: isSmallScreen ? 15 : "5%"
              }}
            >
              Their hours are {hours}
            </Text>
          </View>
        </View>
      </Modal>
    </>
  )
}

export default RestaurantClosedScreen
