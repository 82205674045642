import React, { Fragment, useContext } from "react"
import { FlatList } from "react-native"
import * as SC from "./styles"

import useModal from "../../hooks/useModal"
import AuthContext from "../../auth/context"
import modals from "../../config/modals"
import ContactScreen from "../../screens/ContactScreen"
import AddRestaurantScreen from "../../screens/AddRestaurantScreen"
import FooterLink from "../FooterLink"
import { getLocalLogo } from "../../config/Globals"

const footerLinks = [
  [{ title: "About Us", to: "About" }],
  [{ title: modals.CONTACT_US, Modal: ContactScreen }],
  [{ title: modals.ADD_RESTAURANT, Modal: AddRestaurantScreen }]
]

const Footer = props => {
  const { isSmallScreen } = useContext(AuthContext)
  const { toggleModal } = useModal()

  const handleToggle = modal => () => {
    toggleModal(modal)
  }

  return (
    <SC.FooterWrapper {...props}>
      <SC.Logo src={getLocalLogo(false)} />
      <SC.FooterList
        data={footerLinks}
        horizontal
        keyExtractor={(_, i) => i}
        renderItem={({ item: column }) => (
          <FlatList
            data={column}
            keyExtractor={item => item.title}
            renderItem={({ item: { title, to, Modal } }) => (
              <Fragment>
                <FooterLink
                  title={title}
                  to={!Modal && to}
                  onPress={Modal && handleToggle(title)}
                />
                {Modal && <Modal />}
              </Fragment>
            )}
          />
        )}
      />
      <SC.CopyrightText style={{ fontSize: isSmallScreen && 10 }}>
        {"\u00A9"}2021 The Restaurant Group
      </SC.CopyrightText>
    </SC.FooterWrapper>
  )
}

export default Footer
