import React from "react"
import { View } from "react-native"
import RadioForm from "react-native-simple-radio-button"

import colors from "../config/colors"

function AppRadioButton({
  radio_props,
  initialIndex,
  onPress,
  formHorizontal = false,
  groupIndex,
  onSelected
}) {
  const [isSelected, setIsSelected] = React.useState(false)
  let flag = true

  // const assignPrice = () => {
  //   for (let i = 0; i < radio_props.length; ++i) {
  //     radio_props[i].label =
  //       radio_props[i].label + "                   +$" + radio_props[i].value;
  //   }
  // };

  return (
    <View>
      <RadioForm
        radio_props={radio_props}
        formHorizontal={formHorizontal}
        initial={initialIndex}
        buttonColor={colors.medium}
        selectedButtonColor={colors.medium}
        onPress={(value, index) => {
          setIsSelected(true)
          onPress(value, groupIndex, index)
          if (typeof onSelected !== "undefined") onSelected()
        }}
        buttonSize={10}
      />
    </View>
  )
}

export default AppRadioButton
