import React from "react"
import { StyleSheet, View, Platform } from "react-native"
import colors from "../../config/colors"

function ItemSeparator({ style, separator = true }) {
  if (separator) {
    return <View style={[styles.separator, style]} />
  } else {
    return null
  }
}

const styles = StyleSheet.create({
  separator: {
    width: "100%",
    height: 3,
    backgroundColor: colors.light,
    marginVertical: Platform.OS === "web" ? 30 : 10
  }
})

export default ItemSeparator
