import React from "react"
import { View, StyleSheet, FlatList, Text } from "react-native"

import AppRadioButton from "../AppRadioButton"
import AppCheckBox from "../AppCheckBox"
import MenuContext from "../../auth/menu"
import { setOption, getItemPrice, canOrder } from "../../config/Options"
import AuthContext from "../../auth/context"

function ItemOptions({
  isCheckBox = false,
  data,
  groupIndex,
  onSelectedButton
}) {
  const { setPrice, quantity, setButtonDisabled } =
    React.useContext(MenuContext)
  const { isSmallScreen } = React.useContext(AuthContext)

  if (isCheckBox) {
    var ncols = !isSmallScreen && data.length > 6 ? 2 : 1
    return (
      <View style={styles.container}>
        <FlatList
          data={data}
          keyExtractor={item => item.label}
          numColumns={ncols}
          initialNumToRender={50}
          listKey={"B" + groupIndex}
          renderItem={({ item, index }) => (
            <AppCheckBox
              title={item.label}
              price={item.price}
              isChecked={item.isChecked || false}
              groupIndex={groupIndex}
              index={index}
            />
          )}
        />
      </View>
    )
  } else {
    return (
      <AppRadioButton
        radio_props={data}
        style={styles.text}
        onPress={(value, groupIndex, index) => {
          setOption(false, groupIndex, null, index, null, value)
          setPrice(getItemPrice() * quantity)
          setButtonDisabled(!canOrder())
        }}
        onSelected={onSelectedButton}
        initialIndex={null}
        groupIndex={groupIndex}
      />
    )
  }
}

const styles = StyleSheet.create({
  container: {
    margin: 3
  },
  text: {
    fontWeight: "100",
    fontSize: 12
  }
})

export default ItemOptions
