import React, { useState, useContext, useEffect, Fragment } from "react"
import {
  View,
  StyleSheet,
  TouchableWithoutFeedback,
  Platform
} from "react-native"
import { MaterialCommunityIcons } from "@expo/vector-icons"
import { GooglePlacesAutocomplete } from "react-native-google-places-autocomplete"
import Modal from "modal-enhanced-react-native-web"

import AuthContext from "../../auth/context"
import appStorage from "../../utility/cache"
import colors from "../../config/colors"
import {
  getAddress,
  getModalDimensions,
  updateLocation,
  getLocationCoordinates
} from "../../config/Globals"
import { getIsDelivery, updateIsDelivery } from "../../config/Cart"
import { google as googleKey } from "../../auth/keys.json"
import Text from "../Text"
import Button from "../Button"
import Hoverable from "../Hoverable"

function AddressTag({ navigation }) {
  const { isSmallScreen, user, setCoordinates, setIsDelivery } =
    useContext(AuthContext)

  const styles = StyleSheet.create({
    addressTag: {
      alignItems: "center",
      alignSelf: "center",
      justifyContent: "space-between",
      flexDirection: "row",
      backgroundColor: colors.white,
      height: 30,
      width: isSmallScreen ? "100%" : null
    },
    button: {
      position: "absolute",
      top: "90%"
    },
    changeable: {
      fontSize: 16,
      fontWeight: "bold",
      color: colors.wseorange,
      fontFamily: Platform.OS === "android" ? "Roboto" : "Arial"
    },
    container: {
      flexDirection: "row",
      width: isSmallScreen ? "100%" : null
    },
    input: {
      bottom: 20,
      backgroundColor: colors.light,
      borderRadius: 20
    },
    modal: {
      backgroundColor: colors.white,
      margin: 3,
      width: "30%",
      height: 500,
      borderRadius: "3%",
      justifyContent: "space-between",
      alignSelf: "center"
    },
    text: {
      fontSize: 14,
      fontWeight: "bold",
      fontFamily: Platform.OS === "android" ? "Roboto" : "Arial"
    }
  })

  const { width, controlwidth, mincontrolwidth, height } =
    getModalDimensions(isSmallScreen)
  const [modalVisible, setModalVisible] = useState(false)
  const [isDeliveryLocal, setIsDeliveryLocal] = useState(getIsDelivery())
  const [location, setLocation] = useState("")

  useEffect(() => {
    setLocation(getAddress(true))
  }, [])

  return (
    <Fragment>
      <View style={styles.addressTag}>
        <View style={{ flexDirection: "row", marginRight: 15 }}>
          <Hoverable>
            {isHovered => (
              <View
                style={{
                  backgroundColor:
                    isHovered && !isDeliveryLocal ? colors.light : null,
                  borderRadius: 10
                }}
              >
                <Button
                  title="Delivery"
                  color={isDeliveryLocal ? "medium" : ""}
                  styleButton={{
                    width: isSmallScreen ? 70 : 100,
                    height: 25,
                    borderRadius: 5
                  }}
                  styleText={{
                    color: isDeliveryLocal ? colors.white : colors.black
                  }}
                  onPress={async () => {
                    setIsDeliveryLocal(true)
                    setIsDelivery(true)
                    updateIsDelivery(true)
                    await appStorage.store("ordertype", "true")
                  }}
                  isSmallScreen={isSmallScreen}
                />
              </View>
            )}
          </Hoverable>
          <Hoverable>
            {isHovered => (
              <View
                style={{
                  backgroundColor:
                    isHovered && isDeliveryLocal ? colors.light : null,
                  borderRadius: 10
                }}
              >
                <Button
                  title="Pickup"
                  color={isDeliveryLocal ? "" : "medium"}
                  styleButton={{
                    width: isSmallScreen ? 60 : 80,
                    height: 25,
                    borderRadius: 5
                  }}
                  styleText={{
                    color: isDeliveryLocal ? colors.black : colors.white
                  }}
                  onPress={async () => {
                    setIsDeliveryLocal(false)
                    setIsDelivery(false)
                    updateIsDelivery(false)
                    await appStorage.store("ordertype", "false")
                  }}
                  isSmallScreen={isSmallScreen}
                />
              </View>
            )}
          </Hoverable>
        </View>
        <Hoverable>
          {isHovered => (
            <TouchableWithoutFeedback onPress={() => setModalVisible(true)}>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingVertical: 10,
                  marginTop: 5,
                  backgroundColor: isHovered ? colors.light : null,
                  borderRadius: 10
                }}
              >
                <Text style={styles.changeable} numberOfLines={1}>
                  {location}
                </Text>
                <MaterialCommunityIcons
                  name="chevron-down"
                  size={25}
                  color="#F5821F"
                />
              </View>
            </TouchableWithoutFeedback>
          )}
        </Hoverable>
      </View>
      <Modal
        isVisible={modalVisible}
        onBackdropPress={() => setModalVisible(false)}
        animationIn={isSmallScreen ? "zoomIn" : "slideInLeft"}
        animationOut={isSmallScreen ? "zoomOut" : "slideOutLeft"}
      >
        <View
          style={{
            width: width,
            height: isSmallScreen ? height / 2 : height,
            minWidth: 300,
            minHeight: 400,
            justifyContent: "space-between",
            alignItems: "center",
            alignSelf: isSmallScreen ? null : "flex-start",
            backgroundColor: colors.white,
            borderRadius: width * 0.02
          }}
        >
          <View
            style={{
              width: controlwidth,
              minWidth: mincontrolwidth,
              marginTop: 50
            }}
          >
            <GooglePlacesAutocomplete
              styles={{
                description: {
                  color: colors.black
                },
                listView: {
                  paddingVertical: 10
                },
                separator: {
                  borderColor: colors.medium,
                  borderWidth: 1,
                  marginVertical: 10
                },
                textInput: {
                  backgroundColor: colors.light,
                  padding: 10
                },
                textInputContainer: {
                  paddingBottom: 10
                },
                textInputContainer: {
                  backgroundColor: "rgba(0,0,0,0)",
                  borderWidth: 1
                },
                predefinedPlacesDescription: {
                  color: colors.skyblue
                }
              }}
              placeholder="New Location"
              // currentLocation
              // currentLocationLabel="Use Current Location"
              fetchDetails
              GooglePlacesSearchQuery={{}}
              suppressDefaultStyles
              onPress={async (data, details) => {
                await updateLocation(data, details, user)
                setCoordinates(details.geometry.location)
                setLocation(data.structured_formatting.main_text)
                setModalVisible(false)
                navigation.push("Home")
              }}
              // onSubmitEditing={(data) => {
              //   setLocation(
              //     data != null
              //       ? data.structured_formatting.main_text
              //       : "Invalid"
              //   );
              //   if (location != address && location != "Invalid") {
              //     setModalVisible(false);
              //   }
              // }}
              query={{
                key: googleKey,
                language: "en",
                location: getLocationCoordinates(),
                radius: "50000",
                strictbounds: "true",
                types: "address"
              }}
              requestUrl={{
                url: "https://api.westsideeats.com:8080/https://maps.googleapis.com/maps/api",
                useOnPlatform: "web"
              }}
            />
          </View>
          <Button
            title="Set Location"
            onPress={() => setModalVisible(false)}
            styleButton={{
              width: controlwidth,
              minWidth: mincontrolwidth,
              margin: 40
            }}
          />
        </View>
      </Modal>
    </Fragment>
  )
}

export default AddressTag
