import React from "react"
import { TouchableOpacity, StyleSheet, Platform } from "react-native"

import Text from "./Text"
import colors from "../config/colors"

function Changeable({ title, stylesText, styleContainer, onPress }) {
  return (
    <TouchableOpacity
      style={[styles.container, styleContainer]}
      onPress={onPress}
    >
      <Text style={[styles.text, stylesText]}>{title}</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    padding: 15
  },
  text: {
    color: colors.changeable,
    fontFamily: Platform.OS === "android" ? "Roboto" : "Arial",
    fontSize: 16,
    fontWeight: "400"
  }
})

export default Changeable
