import React from "react"
import { View, TextInput } from "react-native"

import colors from "../../config/colors"
import Text from "../Text"

function SpecialInstructionsInput({ insturctions, setInstructions }) {
  return (
    <View style={{ marginBottom: 10 }}>
      <Text
        style={{
          fontSize: 18,
          fontWeight: "bold",
          left: 15,
          marginBottom: 10
        }}
      >
        Special Instructions
      </Text>
      <TextInput
        style={{
          height: 100,
          borderColor: colors.lightgrey,
          borderWidth: 2,
          borderRadius: 2
        }}
        placeholder="Requests that change the item price will not be honored"
        placeholderTextColor={colors.wseblue}
        multiline={true}
        numberOfLines={3}
        onBlur={text => setInstructions(text.target.value)}
      />
    </View>
  )
}

export default SpecialInstructionsInput
