import React, { useContext } from "react"
import { Platform } from "react-native"
import { createStackNavigator } from "@react-navigation/stack"

import OpeningScreen from "../screens/OpeningScreen"
import DeliveryOrPickupScreen from "../screens/DeliveryOrPickupScreen"
import RestaurantInfoScreen from "../screens/RestaurantInfoScreen"
import ItemDetailsScreen from "../screens/ItemDetailsScreen"
import CartScreen from "../screens/CartScreen"
import PaymentScreeen from "../screens/PaymentScreen"
import LoginScreen from "../screens/LoginScreen"
import DeliveryOptionsScreen from "../screens/DeliveryOptionsScreen"
import RegistrationScreen from "../screens/RegistrationScreen"
import HomeScreen from "../screens/HomeScreen"
import WebHeader from "../components/WebHeader"
import MenuItem from "../components/restaurant/MenuItem.web"
import AuthContext from "../auth/context"
import RestaurantOrdersScreen from "../screens/RestaurantOrdersScreen"
import OrdersScreen from "../screens/OrdersScreen"
import OrderConfirmationScreen from "../screens/OrderConfirmationScreen"
import RestaurantClosedScreen from "../screens/RestaurantClosedScreen"
import AboutScreen from "../screens/AboutScreen"

const headerOptions = {
  header: ({ navigation }) => <WebHeader navigation={navigation} />
}
const simpleHeaderOptions = {
  header: ({ navigation }) => <WebHeader navigation={navigation} simpleHeader />
}
const noHeaderOptions = { headerShown: false }

const Stack = createStackNavigator()

const OpeningScreenNavigator = () => {
  if (Platform.OS === "web") {
    const { hasAddress } = useContext(AuthContext)
    const landingScreen = hasAddress ? "Home" : "Opening"

    return (
      <Stack.Navigator initialRouteName={landingScreen}>
        <Stack.Screen
          name="Opening"
          component={OpeningScreen}
          options={noHeaderOptions}
        />
        <Stack.Screen
          name="DeliveryOrPickup"
          component={DeliveryOrPickupScreen}
          options={noHeaderOptions}
        />
        <Stack.Screen
          name="Home"
          component={HomeScreen}
          options={headerOptions}
        />
        <Stack.Screen
          name="Restaurant"
          component={RestaurantInfoScreen}
          options={headerOptions}
        />
        <Stack.Screen
          name="ItemDetails"
          component={ItemDetailsScreen}
          options={headerOptions}
        />
        <Stack.Screen
          name="MenuItem"
          component={MenuItem}
          options={headerOptions}
        />
        <Stack.Screen
          name="Cart"
          component={CartScreen}
          options={simpleHeaderOptions}
        />
        <Stack.Screen
          name="Payment"
          component={PaymentScreeen}
          options={simpleHeaderOptions}
        />
        <Stack.Screen
          name="Login"
          component={LoginScreen}
          options={noHeaderOptions}
        />
        <Stack.Screen
          name="DeliveryOptions"
          component={DeliveryOptionsScreen}
          options={simpleHeaderOptions}
        />
        <Stack.Screen
          name="Registration"
          component={RegistrationScreen}
          options={noHeaderOptions}
        />
        <Stack.Screen
          name="RestaurantOrders"
          component={RestaurantOrdersScreen}
          options={noHeaderOptions}
        />
        <Stack.Screen
          name="Orders"
          component={OrdersScreen}
          options={headerOptions}
        />
        <Stack.Screen
          name="OrderConfirmation"
          component={OrderConfirmationScreen}
          options={headerOptions}
        />
        <Stack.Screen
          name="ClosedRestaurant"
          component={RestaurantClosedScreen}
          options={headerOptions}
        />
        <Stack.Screen
          name="About"
          component={AboutScreen}
          options={simpleHeaderOptions}
        />
      </Stack.Navigator>
    )
  }

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Opening"
        component={OpeningScreen}
        options={noHeaderOptions}
      />
      <Stack.Screen
        name="Home"
        component={HomeScreen}
        options={headerOptions}
      />
      <Stack.Screen
        name="Restaurant"
        component={RestaurantInfoScreen}
        options={headerOptions}
      />
      <Stack.Screen
        name="ItemDetails"
        component={ItemDetailsScreen}
        options={headerOptions}
      />
      <Stack.Screen
        name="Cart"
        component={CartScreen}
        options={simpleHeaderOptions}
      />
      <Stack.Screen
        name="Payment"
        component={PaymentScreeen}
        options={simpleHeaderOptions}
      />
      <Stack.Screen
        name="Login"
        component={LoginScreen}
        options={simpleHeaderOptions}
      />
      <Stack.Screen
        name="DeliveryOptions"
        component={DeliveryOptionsScreen}
        options={simpleHeaderOptions}
      />
      <Stack.Screen
        name="Registration"
        component={RegistrationScreen}
        options={simpleHeaderOptions}
      />
    </Stack.Navigator>
  )
}

export default OpeningScreenNavigator
