let options
let item
let price
let menuOptions
let removedOptions = []

function setMenuItem(menuItem) {
  item = menuItem
  price = menuItem.price
}

function initializeOptions(oldOptions, oldPrice) {
  removedOptions = []
  if (!oldOptions) {
    let length = typeof menuOptions !== "undefined" ? menuOptions.length : 0
    options = new Array(length)

    for (let i = 0; i < length; i++) {
      if (menuOptions[i].condition) {
        options[i] = {
          isCheckBox: true,
          isShared: menuOptions[i].isShared || false,
          header: menuOptions[i].header,
          selections: [],
          required: menuOptions[i].required
        }
      } else {
        options[i] = {
          isCheckBox: false,
          isShared: menuOptions[i].isShared || false,
          header: menuOptions[i].header,
          selection: "",
          required: menuOptions[i].required,
          price: 0.0,
          prevPrice: 0.0
        }
      }
    }
  } else {
    options = oldOptions
    price = oldPrice
  }
}

function clearOptions() {
  options = null
  removedOptions = []
}

function setMenuOptions(opts, includeOpts) {
  menuOptions = opts
  if (includeOpts != null) {
    for (let i = 0; i < menuOptions.length; ++i) {
      for (let j = 0; j < menuOptions[i].list.length; ++j) {
        for (let m = 0; m < includeOpts.length; ++m) {
          for (let n = 0; n < includeOpts[m].items.length; ++n) {
            if (menuOptions[i].list[j].label == includeOpts[m].items[n]) {
              menuOptions[i].list[j].price = 0
              for (let o = 0; o < includeOpts[m].header.length; ++o) {
                if (menuOptions[i].header == includeOpts[m].header[o])
                  menuOptions[i].list[j].isChecked = true
              }
            }
          }
        }
      }
    }
  }
}

function getMenuOptions() {
  return typeof menuOptions === "undefined" ? [] : menuOptions
}

function setOption(isCheckBox, groupIndex, name, index, remove, optionPrice) {
  if (isCheckBox) {
    if (!remove) {
      let length = options[groupIndex].selections
        ? options[groupIndex].selections.length
        : 0
      if (!menuOptions[groupIndex].list[index].isChecked) {
        options[groupIndex].selections[length] = name
        price = price + optionPrice
      } else {
        if (removedOptions.length > 1) {
          let removeIndex = removedOptions.indexOf(
            menuOptions[groupIndex].list[index].label
          )
          removedOptions.splice(removeIndex, removeIndex)
        } else {
          removedOptions = []
        }
      }
    } else {
      if (options[groupIndex].selections.length > 1) {
        let removeIndex = options[groupIndex].selections.indexOf(name)
        if (menuOptions[groupIndex].list[index].isChecked) {
          removedOptions.push(menuOptions[groupIndex].list[index].label)
        } else {
          options[groupIndex].selections.splice(removeIndex, removeIndex)
        }
      } else {
        if (menuOptions[groupIndex].list[index].isChecked) {
          removedOptions.push(menuOptions[groupIndex].list[index].label)
        } else {
          options[groupIndex].selections = []
        }
      }
      price = price - optionPrice
    }
  } else {
    options[groupIndex].selection = menuOptions[groupIndex].list[index].label
    price =
      options[groupIndex].prevPrice != 0.0
        ? price - options[groupIndex].prevPrice + optionPrice
        : price + optionPrice
    options[groupIndex].prevPrice = optionPrice
  }
}

function canOrder() {
  for (let i = 0; i < options.length; i++) {
    if (options[i].isCheckBox) {
      if (options[i].required && !options[i].selections) {
        return false
      }
    } else {
      if (options[i].required && !options[i].selection) {
        return false
      }
    }
  }
  return true
}

function getOptions() {
  let optionsList = ""
  options.forEach(option => {
    if (option.isCheckBox) {
      option.selections.forEach(selection => {
        if (
          option.selections != [] &&
          option.selections != "" &&
          option.isShared &&
          optionsList.indexOf(option.header) == -1
        ) {
          optionsList +=
            (optionsList.charAt(optionsList.length - 1) == " " ||
            optionsList.length == 0
              ? ""
              : ", \n") +
            option.header +
            ": "
        }
        optionsList +=
          (optionsList.charAt(optionsList.length - 1) == " " ||
          optionsList.length == 0
            ? ""
            : ", ") + selection
      })
    } else {
      if (
        option.selections != [] &&
        option.selections != "" &&
        option.isShared &&
        optionsList.indexOf(option.header) == -1
      ) {
        optionsList +=
          (optionsList.charAt(optionsList.length - 1) == " " ||
          optionsList.length == 0
            ? ""
            : ", \n") +
          option.header +
          ": "
      }
      optionsList +=
        (optionsList.charAt(optionsList.length - 1) == " " ||
        optionsList.length == 0
          ? ""
          : ", ") +
        (option.selection.indexOf("$") == -1
          ? option.selection
          : option.selection
              .substring(
                0,
                option.selection.lastIndexOf(" ") == -1
                  ? option.selection.length
                  : option.selection.lastIndexOf(" ")
              )
              .trim())
    }
  })
  if (removedOptions.length > 0) {
    optionsList +=
      (optionsList.charAt(optionsList.length - 1) == " " ||
      optionsList.length == 0
        ? ""
        : ", ") +
      "\n" +
      "Removed: "
    for (let i = 0; i < removedOptions.length; ++i) {
      optionsList +=
        (optionsList.charAt(optionsList.length - 1) == " " ||
        optionsList.length == 0
          ? ""
          : ", ") + removedOptions[i]
    }
  }
  return optionsList
}

function getItemPrice() {
  return price
}

function getRawOptions() {
  return options
}

export {
  setMenuItem,
  initializeOptions,
  clearOptions,
  setOption,
  canOrder,
  getOptions,
  getItemPrice,
  setMenuOptions,
  getMenuOptions,
  getRawOptions
}
