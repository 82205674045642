import React from "react"
import {
  View,
  StyleSheet,
  Image,
  TouchableWithoutFeedback,
  FlatList,
  Dimensions,
  ScrollView
} from "react-native"
import Modal from "modal-enhanced-react-native-web"
import Toast from "react-native-tiny-toast"
import Hoverable from "../Hoverable"

import List from "../List"
import Text from "../Text"
import Button from "../Button"
import colors from "../../config/colors"
import ItemCounter from "./ItemCounter"
import { addItem, isSameRestaurant } from "../../config/Cart"
import AuthContext from "../../auth/context"
import MenuContext from "../../auth/menu"
import {
  setMenuItem,
  initializeOptions,
  getItemPrice,
  getOptions,
  setMenuOptions,
  getMenuOptions,
  canOrder,
  getRawOptions
} from "../../config/Options"
import { getMenu } from "../../config/Globals"
import SpecialInstructionsInput from "./SpecialInstructionsInput"
import CloseButton from "../CloseButton"
import ReplaceOrderModal from "./ReplaceOrderModal"

function MenuItem({ menuItem }) {
  const { numItems, setNumItems, isSmallScreen } = React.useContext(AuthContext)
  const [modalVisible, setModalVisible] = React.useState(false)
  const [replaceOrderModalVisible, setReplaceOrderModalVisible] =
    React.useState(false)
  const [newItem, setNewItem] = React.useState({})
  const [oldOptions, setOldOptions] = React.useState(null)
  const [buttonDisabled, setButtonDisabled] = React.useState(true)
  const [price, setPrice] = React.useState(menuItem.price)
  const [quantity, setQuantity] = React.useState(1)
  // const [menuOptions, setMenuOptions] = React.useState(null);
  const [instructions, setInstructions] = React.useState("")

  const styles = StyleSheet.create({
    content: {
      backgroundColor: colors.white,
      paddingHorizontal: 10,
      paddingTop: 10
    },
    itemImage: {
      width: "100%",
      height: 150,
      marginTop: -5
    },
    titleContainer: {
      backgroundColor: colors.white,
      width: isSmallScreen ? Dimensions.get("window").width - 20 : 650,
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      flexDirection: "row",
      height: 50,
      justifyContent: "space-between",
      alignItems: "center",
      paddingHorizontal: 10,
      zIndex: 2
    },
    subHeader: {
      left: 15,
      fontSize: 18,
      fontWeight: "bold"
    },
    menuItem: {
      flexDirection: "row",
      alignItems: "center",
      padding: 5,
      paddingLeft: 0,
      borderRadius: 7,
      margin: 5,
      marginBottom: 10,
      borderColor: colors.light,
      borderWidth: 1,
      width: 300
    },
    description: {
      color: colors.medium,
      fontSize: 14
    },
    detailsContainer: {
      marginLeft: 10,
      justifyContent: "center",
      flex: 1
    },
    image: {
      width: 70,
      height: 70,
      borderRadius: 10
    },
    price: {
      fontFamily: "monospace",
      fontSize: 13
    },
    itemName: {
      fontWeight: "bold",
      fontSize: 16
    },
    toastContainer: {
      borderRadius: 30,
      backgroundColor: colors.black,
      width: 150
    },
    toastText: {
      fontWeight: "bold",
      fontSize: 18
    },
    required: {
      fontSize: 13,
      textTransform: "uppercase",
      color: colors.white
    }
  })

  return (
    <MenuContext.Provider
      value={{
        price,
        setPrice,
        quantity,
        setQuantity,
        buttonDisabled,
        setButtonDisabled
      }}
    >
      <Hoverable>
        {isHovered => (
          <TouchableWithoutFeedback
            delayLongPress={isSmallScreen ? 100 : 50}
            onLongPress={() => {
              let options
              let options1
              let includedOptions
              let m = JSON.parse(JSON.stringify(getMenu()))
              if (menuItem.options !== null || menuItem.options !== []) {
                options = menuItem.item.options
                includedOptions = menuItem.item.includedOptions || null
              }
              if (menuItem.optionsRef !== null) {
                for (let i = 0; i < m.sharedOptions.length; ++i) {
                  if (m.sharedOptions[i].name == menuItem.optionsRef) {
                    if (options == null) {
                      options = m.sharedOptions[i].options
                      includedOptions = menuItem.item.includedOptions || null
                    } else {
                      options1 = m.sharedOptions[i].options
                      options = options.concat(options1)
                      includedOptions = menuItem.item.includedOptions || null
                    }
                    break
                  }
                }
              }
              setMenuOptions(options, includedOptions)
              setMenuItem(menuItem)
              initializeOptions(oldOptions, price)
              setButtonDisabled(!canOrder())
              setModalVisible(true)
            }}
          >
            <View
              style={[
                styles.menuItem,
                isHovered && {
                  backgroundColor: "lightgrey"
                }
              ]}
            >
              <View style={styles.detailsContainer}>
                <Text style={styles.itemName} numberOfLines={1}>
                  {menuItem.itemName}
                </Text>
                {menuItem.description && (
                  <Text style={styles.description} numberOfLines={6}>
                    {menuItem.description}
                  </Text>
                )}
                <Text style={styles.price} numberOfLines={1}>
                  ${menuItem.price}
                </Text>
              </View>
              {menuItem.image !== null && (
                <Image
                  style={styles.image}
                  source={{ uri: `${menuItem.image}` }}
                />
              )}
            </View>
          </TouchableWithoutFeedback>
        )}
      </Hoverable>
      <Modal
        isVisible={modalVisible}
        onBackdropPress={() => {
          setModalVisible(false)
          setOldOptions(getRawOptions())
        }}
        animationIn="zoomIn"
        animationOut="zoomOut"
      >
        <View
          style={{
            maxHeight: Dimensions.get("window").height - 40,
            width: isSmallScreen ? Dimensions.get("window").width - 20 : 650,
            alignSelf: "center"
          }}
        >
          <View style={styles.titleContainer}>
            <View></View>
            <Text
              style={{
                fontFamily: "sans-serif",
                fontWeight: "bold"
              }}
            >
              {menuItem.itemName}
            </Text>
            <CloseButton onPress={() => setModalVisible(false)} />
          </View>
          <ScrollView showsHorizontalScrollIndicator={false}>
            <View
              style={{
                alignItems: "center"
              }}
            >
              {menuItem.image !== null && (
                <Image
                  style={styles.itemImage}
                  source={{
                    uri: `${menuItem.image}`
                  }}
                />
              )}
            </View>
            <FlatList
              showsVerticalScrollIndicator={false}
              style={styles.content}
              listKey={menuItem.itemName}
              ListHeaderComponent={
                <>
                  <FlatList
                    data={getMenuOptions()}
                    keyExtractor={item => item.header}
                    renderItem={({ item, index }) => (
                      <List
                        style={styles.subHeader}
                        data={item.list}
                        headerData={item}
                        isCheckBox={item.condition}
                        groupIndex={index}
                      />
                    )}
                  />
                  <SpecialInstructionsInput
                    insturctions={instructions}
                    setInstructions={setInstructions}
                  />
                </>
              }
            />
          </ScrollView>
          <ReplaceOrderModal
            modalVisible={replaceOrderModalVisible}
            setModalVisible={setReplaceOrderModalVisible}
            setMenuItemModalVisible={setModalVisible}
            menuItem={newItem}
          />
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-around",
              backgroundColor: colors.light,
              borderBottomLeftRadius: 20,
              borderBottomRightRadius: 20
            }}
          >
            <ItemCounter
              onChange={value => {
                setPrice(getItemPrice() * value)
                setQuantity(value)
              }}
            />
            <Button
              title={"Add to Cart: $" + price.toFixed(2)}
              disabled={buttonDisabled}
              color={buttonDisabled ? "lightgrey" : "green"}
              styleButton={{ width: isSmallScreen ? 150 : 200 }}
              styleText={{
                fontSize: isSmallScreen ? 12 : 14,
                color: buttonDisabled ? colors.medium : colors.white
              }}
              isSmallScreen={isSmallScreen}
              onPress={() => {
                const ni = {
                  itemName: menuItem.itemName,
                  price: price,
                  description: menuItem.description,
                  image: menuItem.image,
                  quantity: quantity,
                  options: getOptions(),
                  restaurantId: menuItem.restaurantId,
                  restaurantName: menuItem.restaurantName,
                  relayProducerKey: menuItem.relayProducerKey,
                  specialInstructions: instructions,
                  type: menuItem.type
                }
                setNewItem(ni)
                if (isSameRestaurant(menuItem.restaurantId)) {
                  addItem(ni)
                  // setNumItems(numItems + quantity);
                  setOldOptions(getRawOptions())
                  setModalVisible(false)
                  Toast.show("Added to Cart", {
                    containerStyle: styles.toastContainer,
                    textStyle: styles.toastText
                  })
                } else {
                  setReplaceOrderModalVisible(true)
                }
              }}
            />
          </View>
        </View>
      </Modal>
    </MenuContext.Provider>
  )
}

export default MenuItem
