import React from "react"
import {
  StyleSheet,
  FlatList,
  View,
  TouchableWithoutFeedback,
  Platform,
  Dimensions
} from "react-native"

import Text from "../Text"
import colors from "../../config/colors"
import Hoverable from "../Hoverable"
import AuthContext from "../../auth/context"
import { TouchableOpacity } from "react-native-gesture-handler"

function OrderCard({ order, onPress }) {
  const { isSmallScreen } = React.useContext(AuthContext)
  let toppings = ""

  const styles = StyleSheet.create({
    touchable: {
      marginBottom: 0,
      backgroundColor: colors.white
    },
    orderCard: {
      backgroundColor: colors.white,
      borderRadius: 3,
      shadowOffset: { width: 1, height: 1 },
      shadowOpacity: 0.5,
      shadowColor: colors.medium,
      shadowRadius: 2,
      marginVertical: 10,
      width: isSmallScreen ? Dimensions.get("window").width - 14 : "100%",
      alignSelf: "center"
    }
  })

  return (
    <Hoverable>
      {isHovered => (
        <TouchableWithoutFeedback>
          <View
            style={[
              styles.orderCard,
              isHovered && {
                backgroundColor: colors.lightgrey,
                shadowOffset: { width: 3, height: 3 }
              }
            ]}
          >
            <View
              style={{
                flexDirection: "row",
                marginBottom: 10
              }}
            >
              <TouchableOpacity onPress={() => onPress(order.restaurantId)}>
                <View
                  style={{
                    backgroundColor: colors.black,
                    padding: 5,
                    borderTopLeftRadius: 3,
                    borderBottomRightRadius: 3
                  }}
                >
                  <Text
                    style={{
                      color: colors.white,
                      fontWeight: "bold",
                      fontFamily: Platform.OS === "android" ? "Roboto" : "Arial"
                    }}
                    numberOfLines={1}
                  >
                    {order.restaurantName}
                  </Text>
                </View>
              </TouchableOpacity>
              <View style={{ alignItems: "flex-end", flex: 1, padding: 5 }}>
                <Text numberOfLines={1}>${order.subtotal}</Text>
              </View>
            </View>
            <View>
              <FlatList
                data={order.items}
                keyExtractor={item => item.name}
                renderItem={({ item }) => (
                  // for (let i = 0; i < item.options.length; ++i) {
                  //   toppings +=
                  //     i === item.options.length - 1 &&
                  //     index !== order.items.length - 1
                  //       ? item.options[i] + " · "
                  //       : item.options[i]
                  // }
                  <View>
                    <Text style={{ fontSize: 15 }}>
                      {item.quantity + " " + item.itemName}
                    </Text>
                    <View style={{ paddingLeft: 15 }}>
                      <Text style={{ fontSize: 13 }}>{item.options}</Text>
                    </View>
                    <View style={{ marginVertical: 5 }} />
                  </View>
                )}
              />
            </View>
          </View>
        </TouchableWithoutFeedback>
      )}
    </Hoverable>
  )
}

export default OrderCard
