import React from "react"
import { View, TouchableWithoutFeedback } from "react-native"
import { format } from "date-fns"

import AuthContext from "../../auth/context"
import colors from "../../config/colors"
import Text from "../Text"
import { makeServerAddress } from "../../config/Globals"

function OrderTag({ order, onPress, width }) {
  const details = order.order
  var date = new Date(
    order.time // .substring(0, 10) + " " + order.time.substring(11, 19) + " UTC"
  )
  const formattedTime = format(date, "MM/dd h:mm a")
  const displayTime = formattedTime

  const itemsOrItem = details.numItems == 1 ? " Item" : " Items"
  const { orderSelected, setOrderSelected } = React.useContext(AuthContext)

  return (
    <TouchableWithoutFeedback
      onPress={() => {
        onPress(order._id, displayTime)
        setOrderSelected(order._id)
      }}
    >
      <View
        style={{
          backgroundColor:
            order._id == orderSelected ? colors.lightgrey : colors.white,
          width: width,
          padding: 10,
          marginBottom: 10,
          borderRadius: 2
        }}
      >
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Text>{details.numItems + itemsOrItem} </Text>
          <Text>{order.status}</Text>
        </View>
        <Text>{displayTime}</Text>
      </View>
    </TouchableWithoutFeedback>
  )
}

export default OrderTag
