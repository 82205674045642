import React, { Fragment } from "react"
import { View } from "react-native"

import colors from "../config/colors"
import Button from "../components/Button"
import SEO from "../components/SEO"
import Text from "../components/Text"

function OrderConfirmationScreen({ navigation, route }) {
  const { error, isDelivery, phonenumber } = route.params
  return (
    <Fragment>
      <SEO title="Order Confirmation" />
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        {error && (
          <View>
            <Text
              style={{
                color: colors.error,
                fontSize: 20
              }}
            >
              Oops... Something went wrong
            </Text>
            <Text
              style={{
                color: colors.error,
                fontSize: 20
              }}
            >
              {error}
            </Text>
          </View>
        )}
        {!error && (
          <View>
            <Text style={{ fontSize: 30 }}>
              Your order has been placed successfully!
            </Text>
            {!isDelivery && (
              <Text tyle={{ color: colors.wseblue }}>
                We will send an email and text you at {phonenumber} when your
                order is ready for pickup
              </Text>
            )}
          </View>
        )}
        <Button
          title="Return Home"
          color="wseblue"
          onPress={() => navigation.navigate("Home")}
        />
      </View>
    </Fragment>
  )
}

export default OrderConfirmationScreen
