import React from "react"
import Counter from "react-native-counters"
import { MaterialCommunityIcons } from "@expo/vector-icons"
import colors from "../../config/colors"

function ItemCounter({ onChange }) {
  return (
    <Counter
      min={1}
      start={1}
      buttonStyle={{
        borderColor: colors.light
      }}
      onChange={value => onChange(value)}
      countTextStyle={{
        color: colors.black,
        fontSize: 10
      }}
      plusIcon={() => (
        <MaterialCommunityIcons
          name="plus-circle"
          size={20}
          color={colors.medium}
        />
      )}
      minusIcon={() => (
        <MaterialCommunityIcons
          name="minus-circle"
          size={20}
          color={colors.medium}
        />
      )}
    />
  )
}

export default ItemCounter
