import React from "react"
import { TextInput, StyleSheet } from "react-native"

const LoginInput = props => {
  return <TextInput {...props} style={{ ...styles.input, ...props.style }} />
}

const styles = StyleSheet.create({
  input: {
    height: 50,
    borderColor: "black",
    borderWidth: 1,
    marginVertical: 10,
    padding: 5,
    borderRadius: 20,
    width: "80%",
    justifyContent: "center"
  }
})

export default LoginInput
