import React, { useState, useContext, useEffect, Fragment } from "react"
import { View, FlatList, Platform } from "react-native"
import StripeCheckout from "react-stripe-checkout"
import { v4 as uuidV4 } from "react-native-uuid"
import { useIsFocused } from "@react-navigation/native"

import AuthContext from "../auth/context"
import appStorage from "../utility/cache"
import colors from "../config/colors"
import {
  getAddress,
  getLocationInfo,
  makeServerAddress,
  stripeKey
} from "../config/Globals"
import {
  getCart,
  clearCart,
  getIsDelivery,
  updateIsDelivery,
  getPayOnline,
  updatePayOnline,
  findItemByName,
  addItem
} from "../config/Cart"
import AppRadioButton from "../components/AppRadioButton"
import Button from "../components/Button"
import DeliveryStripe from "../components/DeliveryStripe"
import ItemSeparator from "../components/restaurant/ItemSeparator"
import LoadingIndicator from "../components/LoadingIndicator"
import SEO from "../components/SEO"
import Text from "../components/Text"
import TipInput from "../components/cart/TipInput"
import { TextInput } from "react-native"
import Toast from "react-native-tiny-toast"

const deliveryOrPickupOptions = [
  {
    value: 1,
    label: "Delivery"
  },
  {
    value: 2,
    label: "Pickup"
  }
]

const paymentOptions = [
  {
    value: 1,
    label: "Pay at Store"
  },
  {
    value: 2,
    label: "Pay Online"
  }
]

const CartScreen = ({ navigation, route }) => {
  const { canDeliver } = route.params
  const deliveryFee = 4.0
  const isAddressUndefined =
    getLocationInfo().location.address1.includes("undefined")

  const { user, isSmallScreen } = useContext(AuthContext)
  const [address, setAddress] = useState("")
  const [isDelivery, setIsDelivery] = useState(getIsDelivery())
  const [cart, setCart] = useState(getCart())
  const [paymentMethods, setPaymentMethods] = useState([])
  const [cardIndex, setCardIndex] = useState(-1)
  const [payOnline, setPayOnline] = useState(getPayOnline())
  const [orderError, setOrderError] = useState(null)
  const [discount, setDiscount] = React.useState(0.0)
  const [tip, setTip] = useState(parseFloat(cart.subtotal * 0.15))
  const [subTotal, setSubTotal] = React.useState(parseFloat(cart.subtotal))
  const [fee, setFee] = React.useState(parseFloat(cart.fee))
  const t =
    parseFloat(cart.subtotal) +
    parseFloat(cart.fee) +
    (getIsDelivery() ? 4.0 : 0.0) +
    parseFloat(cart.subtotal) * 0.15
  const [total, setTotal] = React.useState(t)
  const [couponCode, setCouponCode] = React.useState("")
  const [appliedCouponCode, setAppliedCouponCode] = React.useState("")
  const [orderProcessing, setOrderProcessing] = useState(false)
  const [apartment, setApartment] = useState("")
  const isFocused = useIsFocused()

  const getPaymentMethods = async () => {
    try {
      setAddress(formatAddress(getAddress(false)))
      const token = await appStorage.get("token")
      if (token) {
        const response = await fetch(
          makeServerAddress(`/user/paymentmethods`),
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            },
            body: JSON.stringify({ token })
          }
        )
        const paymentMethods = await response.json()
        const formattedPaymentMethods = paymentMethods.map(method => ({
          ...method,
          label: `${method.brand} ****-${method.last4} ${method.exp_month}/${method.exp_year}`
        }))
        setPaymentMethods(formattedPaymentMethods)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const applyCoupon = async () => {
    try {
      console.log("in apply coupon");
      if (couponCode !== "GOWSE")
      {
        Toast.show("Invalid Coupon Code", {
          containerStyle: {
            borderRadius: 30,
            backgroundColor: colors.wseorange,
            width: 250
          },
          textStyle: {
            fontWeight: "bold",
            fontSize: 16
          },
          position: 0,
          duration: 3000
        })
        return
      }
      if (!findItemByName("Free Surprise Dessert"))
      {
        const ni = {
          itemName: "Free Surprise Dessert",
          price: 0,
          description: "Free Surprise Dessert",
          image: "",
          quantity: 1,
          options: "",
          restaurantId: cart.restaurantId,
          restaurantName: cart.restaurantName,
          relayProducerKey: "",
          specialInstructions: "",
          type: ""
        }
        addItem(ni)
        setCart(cart => ({
          ...cart
        }))
        Toast.show("Free Surprise Dessert Added", {
          containerStyle: {
            borderRadius: 30,
            backgroundColor: colors.wseorange,
            width: 250
          },
          textStyle: {
            fontWeight: "bold",
            fontSize: 16
          },
          position: 0,
          duration: 3000
        })        
      }
      else {
        Toast.show("Coupon Already Applied", {
          containerStyle: {
            borderRadius: 30,
            backgroundColor: colors.wseorange,
            width: 250
          },
          textStyle: {
            fontWeight: "bold",
            fontSize: 16
          },
          position: 0,
          duration: 3000
        })        
      }  
    } catch (err) {
      console.error(err)
    }
  }
  
  /*
  const applyCoupon = async () => {
    try {
      if (appliedCouponCode.length > 0) {
        Toast.show("Only one coupon allowed", {
          containerStyle: {
            borderRadius: 30,
            backgroundColor: colors.wseorange,
            width: 250
          },
          textStyle: {
            fontWeight: "bold",
            fontSize: 16
          },
          position: 0
        })
        setCouponCode("")
        return
      }

      const authtoken = await appStorage.get("token")
      if (authtoken) {
        const cart = getCart(user.fullName)
        cart.email = user.email
        cart.locationParts.apartment = apartment
        const response = await fetch(makeServerAddress("/coupon/apply"), {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            cart,
            couponCode
          })
        })

        if (response.status === 200) {
          const data = await response.json()
          setDiscount(data.discount)
          const newSubTotal = subTotal - data.discount
          setSubTotal(newSubTotal)
          setFee(0.05 * newSubTotal)
          const newTotal = total - data.discount * 1.05
          setTotal(newTotal)
          setAppliedCouponCode(couponCode)
          Toast.show("Coupon applied successfully", {
            containerStyle: {
              borderRadius: 30,
              backgroundColor: colors.wseorange,
              width: 250
            },
            textStyle: {
              fontWeight: "bold",
              fontSize: 16
            },
            position: 0
          })
        } else {
          Toast.show("Failed to apply coupon", {
            containerStyle: {
              borderRadius: 30,
              backgroundColor: colors.wseorange,
              width: 250
            },
            textStyle: {
              fontWeight: "bold",
              fontSize: 16
            },
            position: 0
          })
        }
        setCouponCode("")

        return response
      }
    } catch (err) {
      console.error(err)
    }
  }
  */

  const sendOrder = async () => {
    try {
      const authtoken = await appStorage.get("token")
      if (authtoken) {
        const cart = getCart(user.fullName)
        cart.customerId =
          cardIndex >= 0 ? paymentMethods[cardIndex].customerId : ""
        cart.email = user.email
        cart.deliveryFee = deliveryFee
        cart.tip = tip
        cart.total = total
        cart.fee = fee
        cart.discount = discount
        cart.subtotal = subTotal
        cart.couponCode = appliedCouponCode

        if (apartment) cart.locationParts = { ...cart.locationParts, apartment }
        const response = await fetch(makeServerAddress("/order"), {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            cart,
            token: authtoken,
            product: { price: total, name: cart.restaurantName }
          })
        })
        return response
      }
    } catch (err) {
      console.error(err)
    }
  }

  function updateTip(newTip) {
    const newTotal = total - tip + newTip
    setTotal(newTotal)
    setTip(newTip)
  }

  const onPlaceOrder = async () => {
    if (cardIndex === -1 && payOnline) return
    if (isDelivery && (isAddressUndefined || !canDeliver)) return

    setOrderProcessing(true)

    const response = await sendOrder()
    const data = await response.json()
    if (response.status !== 400) {
      await clearCart()
      const error = response.status !== 200 && data.error
      navigation.push("OrderConfirmation", {
        error,
        isDelivery,
        phonenumber: user.phonenumber
      })
    } else setOrderError(data.error)

    setOrderProcessing(false)
  }

  async function handleToken(token) {
    const authToken = await appStorage.get("token")
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        stripetoken: token,
        token: authToken,
        product: {
          name: "WSE Food",
          price: 24.67,
          description: "Restaurant 1"
        }
      })
    }
    await fetch(makeServerAddress("/user/paymentmethod"), requestOptions)
    await getPaymentMethods()
  }

  const formatAddress = address => {
    const index = address.indexOf(",")
    return {
      street: address.substring(0, index),
      extra: address.substring(index + 2, address.length + 1)
    }
  }

  const onSelectedButton = () => {}

  useEffect(() => {
    getPaymentMethods()
  }, [])

  useEffect(() => {
    if (isFocused) setCart(getCart())
  }, [isFocused])

  /*
  useEffect(() => {
    if (isFocused) {
      if (!tip) setTip(parseFloat((cart.total * 0.15).toFixed(2)))
      const temp =
        parseFloat(cart.total) +
        parseFloat(tip) +
        (isDelivery ? parseFloat(deliveryFee) : 0)
      setTotal(temp.toFixed(2))
    }
  }, [tip, isDelivery, isFocused])
  */

  return (
    <Fragment>
      <SEO title="Checkout" />
      <View
        style={{
          marginTop: isSmallScreen ? "15%" : "5%",
          marginHorizontal: isSmallScreen ? 20 : "34%"
        }}
      >
        <DeliveryStripe title="User Info">
          <View>
            <Text>{user.fullName}</Text>
            <Text>{address.street}</Text>
            <Text>{address.extra}</Text>
            <TextInput
              value={apartment}
              style={{
                color: colors.dark,
                height: 25,
                maxWidth: 175,
                borderColor: colors.black,
                borderWidth: 2,
                borderRadius: 5,
                marginVertical: 5,
                padding: 5
              }}
              placeholder="Enter apt, suite, or unit no"
              maxLength={5}
              onChangeText={text => setApartment(text.toUpperCase())}
            />
            <Text style={{ marginTop: 7 }}>{user.phonenumber}</Text>
            <Text style={{ marginTop: 7, fontStyle: "italic" }}>
              email: {user.email}
            </Text>
          </View>
        </DeliveryStripe>
        <DeliveryStripe title="Order Details">
          <View>
            <Text
              style={{ paddingBottom: 10, fontSize: 20, color: colors.skyblue }}
            >
              {cart.restaurantName}
            </Text>
            <FlatList
              data={cart.items}
              keyExtractor={() => uuidV4()}
              style={{ marginBottom: 30 }}
              renderItem={({ item }) => {
                const color = item.itemName === "Free Surprise Dessert" ? colors.wseorange : colors.dark
                return (<View style={{ flexDirection: "row" }}>
                  <Text style={{ paddingRight: 10, color: color }}>{item.quantity}</Text>
                  <Text style={{ paddingRight: 15, color: color }}>{item.itemName}</Text>
                  <Text style={{color: color}}>${item.price.toFixed(2)}</Text>
                </View>
              ) }
            }
            />

            {discount > 0 && (
              <View>
                <View
                  style={{
                    flexDirection: "row"
                  }}
                >
                  <View style={{ width: 120 }}>
                    <Text style={{ color: colors.wseorange }}>Discount</Text>
                  </View>
                  <Text style={{ color: colors.wseorange }}>
                    -${discount.toFixed(2)}
                  </Text>
                </View>
              </View>
            )}

            <View
              style={{
                flexDirection: "row"
              }}
            >
              <View style={{ width: 120 }}>
                <Text>Subtotal</Text>
              </View>
              <Text>${subTotal.toFixed(2)}</Text>
            </View>
            <View
              style={{
                flexDirection: "row"
              }}
            >
              <View style={{ width: 120 }}>
                <Text>Fee</Text>
              </View>
              <Text>${fee.toFixed(2)}</Text>
            </View>
            {isDelivery && (
              <View>
                <View
                  style={{
                    flexDirection: "row"
                  }}
                >
                  <View style={{ width: 120 }}>
                    <Text>Delivery Fee</Text>
                  </View>
                  <Text>${deliveryFee.toFixed(2)}</Text>
                </View>
              </View>
            )}
            <ItemSeparator style={{ marginVertical: 5, width: 225 }} />
            <View
              style={{
                flexDirection: "row"
              }}
            >
              <View style={{ width: 120, justifyContent: "center" }}>
                <Text>Tip</Text>
              </View>
              <View style={{ justifyContent: "center", flexDirection: "row" }}>
                <Text>$</Text>
                <TipInput tip={tip.toFixed(2)} setTip={updateTip} />
              </View>
            </View>
            <ItemSeparator style={{ marginVertical: 5, width: 225 }} />
            <View
              style={{
                flexDirection: "row"
              }}
            >
              <View style={{ width: 120 }}>
                <Text>Total</Text>
              </View>
              <Text>${total.toFixed(2)}</Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                paddingTop: 15
              }}
            >
              <TextInput
                style={{
                  height: 30,
                  width: 120,
                  borderColor: colors.black,
                  borderWidth: 2,
                  borderRadius: 5,
                  fontSize: 18,
                  fontFamily: Platform.OS === "android" ? "Roboto" : "Arial",
                  color: colors.dark,
                  justifyContent: "center",
                  alignItems: "center"
                }}
                placeholder="Coupon Code"
                value={couponCode}
                onChangeText={text => setCouponCode(text.toUpperCase())}
              />
              <Button
                title="Apply"
                styleButton={{
                  width: 80,
                  height: 30,
                  backgroundColor: colors.wseblue,
                  marginVertical: 0,
                  marginLeft: 15
                }}
                styleText={{ color: colors.white }}
                onPress={applyCoupon}
                isSmallScreen={isSmallScreen}
              />
            </View>
          </View>
        </DeliveryStripe>
        <DeliveryStripe title="Method">
          <AppRadioButton
            radio_props={deliveryOrPickupOptions}
            initialIndex={isDelivery ? 0 : 1}
            onPress={async value => {
              updateIsDelivery(value === 1)
              setIsDelivery(value === 1)
              updatePayOnline(true)
              setPayOnline(true)
              if (isDelivery) setTotal(total - 4.0)
              else setTotal(total + 4.0)
            }}
          />
        </DeliveryStripe>
        <DeliveryStripe title="Time">
          {isDelivery && <Text>ASAP (30-40 mins)</Text>}
          {!isDelivery && <Text>ASAP (15-20 mins)</Text>}
        </DeliveryStripe>
        <DeliveryStripe title="Payment" separator={false}>
            <View>
              <AppRadioButton
                radio_props={paymentMethods}
                onPress={(v, g, index) => setCardIndex(index)}
                onSelected={onSelectedButton}
                initialIndex={cardIndex}
                groupIndex={0}
              />
              <StripeCheckout
                name="Add Card"
                label="Add Card"
                style={{
                  width: 100,
                  borderRadius: 15
                }}
                panelLabel="Save"
                stripeKey={stripeKey}
                token={handleToken}
                zipCode
              />
            </View>
        </DeliveryStripe>
        {cardIndex === -1 && payOnline && (
          <Text
            style={{ color: colors.error, alignSelf: "center", marginTop: 20 }}
          >
            Please specify payment method
          </Text>
        )}
        {orderError && (
          <Text
            style={{ color: colors.error, alignSelf: "center", marginTop: 20 }}
          >
            {orderError}
          </Text>
        )}
        {isDelivery && isAddressUndefined && (
          <Text
            style={{
              color: colors.error,
              alignSelf: "center",
              marginTop: 20
            }}
          >
            Specify full street address for delivery
          </Text>
        )}
        {isDelivery && !canDeliver && (
          <Text
            style={{ color: colors.error, alignSelf: "center", marginTop: 20 }}
          >
            Restaurant is not in your delivery area, select Pickup
          </Text>
        )}
        <Button
          title={orderProcessing ? "Processing..." : "Place Order"}
          onPress={onPlaceOrder}
          disabled={orderProcessing}
          styleButton={{
            backgroundColor: orderProcessing
              ? colors.fadedWSEBlue
              : colors.wseblue,
            width: 200,
            alignSelf: "center",
            marginTop: 20
          }}
        >
          {orderProcessing && <LoadingIndicator size="small" color="black" />}
        </Button>
      </View>
    </Fragment>
  )
}

export default CartScreen
