import React, { useContext } from "react"
import { Dimensions, FlatList, StyleSheet, View } from "react-native"

import AuthContext from "../../auth/context"
import CuisineCard from "../CuisineCard"

const ListFilter = ({ cuisines }) => {
  const { isSmallScreen } = useContext(AuthContext)
  const dimensions = Dimensions.get("window").width

  const styles = StyleSheet.create({
    container: {
      alignItems: "center",
      marginTop: isSmallScreen ? null : 10
    },
    list: {
      width: isSmallScreen ? dimensions * 0.9 : "100%"
    }
  })

  return (
    <View style={styles.container}>
      <FlatList
        horizontal
        style={styles.list}
        data={cuisines}
        keyExtractor={cuisine => cuisine._id}
        renderItem={({ item: cuisine }) => (
          <CuisineCard
            name={cuisine.name}
            icon={cuisine.icon}
            color={cuisine.color}
          />
        )}
      />
    </View>
  )
}

export default ListFilter
