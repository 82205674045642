import React from "react"
import { View } from "react-native"

import Text from "./Text"
import Changeable from "./Changeable"
import ItemSeparator from "./restaurant/ItemSeparator"
import AuthContext from "../auth/context"

function DeliveryStripe({
  title,
  children,
  changeable = false,
  separator = true
}) {
  const { isSmallScreen } = React.useContext(AuthContext)

  return (
    <>
      <View style={{ flexDirection: "row" }}>
        <View style={{ alignItems: "flex-start" }}>
          <View style={{ width: isSmallScreen ? 125 : 250 }}>
            <Text style={{ fontWeight: "bold" }}>{title}</Text>
            {changeable && (
              <Changeable
                title="Change"
                styleContainer={{ paddingLeft: 0, paddingTop: 5 }}
              />
            )}
          </View>
        </View>
        {children}
      </View>
      {separator && <ItemSeparator />}
    </>
  )
}

export default DeliveryStripe
