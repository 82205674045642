import React, { useState, useContext, useEffect, Fragment } from "react"
import { StyleSheet, FlatList, Dimensions, Platform } from "react-native"

import AuthContext from "../auth/context"
import colors from "../config/colors"
import { makeServerAddress, setMenu as setMenuGlobal } from "../config/Globals"
import Footer from "../components/Footer"
import ItemSeparator from "../components/restaurant/ItemSeparator"
import Loading from "../components/Loading"
import LoadingIndicator from "../components/LoadingIndicator"
import Menu from "../components/restaurant/Menu"
import Restaurant from "../components/restaurant/Restaurant"
import Screen from "../components/Screen"
import SEO from "../components/SEO"

const RestaurantInfoScreen = ({ navigation, route }) => {
  const { isSmallScreen } = useContext(AuthContext)
  const { restaurantId, restaurantName, relayProducerKey } = route.params
  const [restaurantInfo, setRestaurantInfo] = useState(null)
  const [menu, setMenu] = useState(null)

  const getMenu = async () => {
    try {
      const response = await fetch(makeServerAddress(`/menus/${restaurantId}`))
      const loadedMenu = await response.json()
      setMenu(loadedMenu)
      setMenuGlobal(loadedMenu)
    } catch (err) {
      console.error(err.message)
    }
  }

  const getRestaurantInfo = async () => {
    try {
      const response = await fetch(
        makeServerAddress(`/restaurants/${restaurantId}`)
      )
      const loadedRestaurantInfo = await response.json()
      setRestaurantInfo(loadedRestaurantInfo)
    } catch (err) {
      console.error(err.message)
    }
  }

  useEffect(() => {
    getRestaurantInfo()
    getMenu()
  }, [])

  const styles = StyleSheet.create({
    content: {
      width: isSmallScreen ? Dimensions.get("window").width - 10 : 700,
      backgroundColor: colors.white,
      alignSelf: "center"
    },
    indicator: {
      marginTop: 200
    },
    screen: {
      backgroundColor: colors.light
    },
    separator: {
      marginBottom: 5,
      marginTop: 0
    }
  })

  return (
    <Fragment>
      <SEO title={restaurantName} subCategory="Menu" />
      <Screen style={styles.screen}>
        <FlatList
          style={styles.content}
          showsVerticalScrollIndicator={false}
          ListHeaderComponent={
            <Loading
              data={[restaurantInfo, menu]}
              indicator={
                <LoadingIndicator
                  size="large"
                  color="wseblue"
                  style={styles.indicator}
                />
              }
            >
              {restaurantInfo && <Restaurant info={restaurantInfo} />}
              <ItemSeparator style={styles.separator} />
              <Menu
                menu={menu ?? []}
                restaurantId={restaurantId}
                restaurantName={restaurantName}
                relayProducerKey={relayProducerKey}
                onPress={menuItem =>
                  navigation.push("ItemDetails", { menuItem })
                }
              />
            </Loading>
          }
        />
        {Platform.OS === "web" && <Footer />}
      </Screen>
    </Fragment>
  )
}

export default RestaurantInfoScreen
