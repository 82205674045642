import React from "react"
import { View, StyleSheet } from "react-native"

import { TextInput } from "react-native-paper"
import defaultStyles from "../config/styles"

function AppTextInput({ style, ...props }) {
  return (
    <View style={[styles.container, style]}>
      <TextInput
        placeholderTextColor={defaultStyles.colors.medium}
        style={defaultStyles.text}
        {...props}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 10
  },
  startIcon: {
    padding: 10
  },
  endIcon: {
    padding: 10
  }
})

export default AppTextInput
