import React, { Component } from "react"
import {
  View,
  Text,
  StyleSheet,
  TouchableWithoutFeedback,
  Keyboard,
  KeyboardAvoidingView,
  ScrollView,
  Platform
} from "react-native"

import ItemSeparator from "../components/restaurant/ItemSeparator"
import GetCheckoutInfo from "../components/cart/GetCheckoutInfo"
import Button from "../components/Button"

class DeliveryOptionsScreen extends Component {
  constructor(props) {
    super(props)
  }
  state = {}
  render() {
    return (
      <TouchableWithoutFeedback
        onPress={() => {
          Keyboard.dismiss()
        }}
      >
        <View style={styles.screen}>
          <View style={styles.header}>
            <Text style={styles.changable}>Delivery Options</Text>
            <ItemSeparator />
          </View>
          <KeyboardAvoidingView
            behavior={Platform.OS == "ios" ? "padding" : "height"}
          >
            <ScrollView>
              <View style={styles.deliveryHeader}>
                <Text style={styles.deliveryHeaderText}>Delivering To:</Text>
              </View>
              <View style={{ paddingRight: 30 }}>
                <GetCheckoutInfo>Address 1</GetCheckoutInfo>
                <GetCheckoutInfo placeholder="Apt No.">
                  Address 2
                </GetCheckoutInfo>
                <GetCheckoutInfo>City</GetCheckoutInfo>
              </View>
              <View style={{ paddingRight: 193 }}>
                <GetCheckoutInfo style={{ width: "30%" }}>
                  State
                </GetCheckoutInfo>
                <GetCheckoutInfo style={{ width: "30%" }}>
                  Zipcode
                </GetCheckoutInfo>
              </View>
              <View style={{ paddingRight: 30 }}>
                <GetCheckoutInfo>Phone Number</GetCheckoutInfo>
              </View>
              <ItemSeparator
                style={{ backgroundColor: "black", height: 1.5 }}
              />
              <View style={{ paddingLeft: 15, paddingTop: 5 }}>
                <Text style={styles.deliveryHeaderText}>
                  Any special delivery instructions?
                </Text>
              </View>
              <View style={{ alignItems: "center" }}>
                <GetCheckoutInfo
                  style={{ height: 100, width: "85%" }}
                ></GetCheckoutInfo>
              </View>
            </ScrollView>
          </KeyboardAvoidingView>
          <View style={styles.nextScreenButtonContainer}>
            <Button
              title="Review Order"
              color="yellow"
              styleButton={styles.nextScreenButton}
              onPress={() => {
                this.props.navigation.navigate("Home")
              }}
            />
          </View>
        </View>
      </TouchableWithoutFeedback>
    )
  }
}

const styles = StyleSheet.create({
  screen: {
    flex: 1
  },
  header: {
    alignItems: "center",
    top: 10,
    paddingTop: Platform.OS === "ios" ? 40 : 30
  },
  changable: {
    fontSize: 14,
    fontWeight: "bold",
    fontFamily:
      Platform.OS === "android" ? "sans-serif-medium" : "Avenir-Medium"
  },
  deliveryHeader: {
    paddingLeft: 15,
    paddingTop: 15
  },
  deliveryHeaderText: {
    fontSize: 16,
    fontWeight: "bold"
  },
  nextScreenButton: {
    width: "80%",
    height: 50,
    top: 0
  },
  nextScreenButtonContainer: {
    alignItems: "center"
  }
})

export default DeliveryOptionsScreen
