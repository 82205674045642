export default {
  white: "#fff",
  black: "#000",
  yellow: "#f0bd41",
  light: "#f8f4f4",
  medium: "#6e6969",
  dark: "#0c0c0c",
  blue: "#6bb4d1",
  skyblue: "#1faadb",
  facebookBlue: "#3b5998",
  googleBlue: "#3393ff",
  changeable: "#3374ff",
  grey: "#c4c4c4",
  lightgrey: "lightgrey",
  required: "#d85737",
  error: "red",
  green: "#429204",
  wseorange: "#F5821F",
  wseblue: "#25AAE1",
  fadedWSEBlue: "#7bc4e3",
  wseGradient: ["#f0bd41", "#f5821f", "#25aae1"]
}
