import React, { useContext } from "react"
import { StyleSheet, FlatList, Platform, Dimensions } from "react-native"
import { ScrollView } from "react-native-web"

import AuthContext from "../../auth/context"
import { makeFileServerAddress } from "../../config/Globals"
import MenuItem from "./MenuItem"
import MenuSeparator from "./MenuSeparator"
import Text from "../Text"

const Menu = ({
  menu,
  restaurantId,
  restaurantName,
  relayProducerKey,
  onPress
}) => {
  const { isSmallScreen } = useContext(AuthContext)

  return (
    <ScrollView style={styles.screen}>
      <FlatList
        showsVerticalScrollIndicator={false}
        ListHeaderComponent={
          <FlatList
            data={menu.sections}
            initialNumToRender={50}
            keyExtractor={section => section.name}
            renderItem={({ item: section }) => (
              <ScrollView>
                <Text style={styles.text}>{section.name}</Text>
                <FlatList
                  data={section.items}
                  keyExtractor={item => item.name}
                  numColumns={isSmallScreen ? 1 : 2}
                  renderItem={({ item }) => {
                    const menuItem = {
                      itemName: item.name,
                      price: item.price,
                      description: item.description,
                      type: "type" in item ? item.type : "",
                      optionsRef: "optionsRef" in item ? item.optionsRef : null,
                      image:
                        "image" in item
                          ? makeFileServerAddress(item.image)
                          : null,
                      item,
                      restaurantId,
                      restaurantName,
                      relayProducerKey
                    }
                    return (
                      <MenuItem
                        menuItem={menuItem}
                        onPress={() => {
                          if (Platform.OS !== "web") {
                            onPress(item)
                          }
                        }}
                      />
                    )
                  }}
                />
                <MenuSeparator />
              </ScrollView>
            )}
          />
        }
      />
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  list: {
    right: 7
  },
  screen: {
    flex: 1,
    padding: 7
  },
  text: {
    fontWeight: "bold",
    fontSize: 18
  }
})

export default Menu
