import React, { useContext } from "react"
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Platform,
  Image
} from "react-native"

import AuthContext from "../auth/context"
import { makeFileServerAddress } from "../config/Globals"
import colors from "../config/colors"
import Hoverable from "./Hoverable"
import Text from "./Text"

function CuisineCard({ name, icon, color }) {
  const { selectedCuisine, setSelectedCuisine, isSmallScreen } =
    useContext(AuthContext)

  const handleCuisineChange = () =>
    setSelectedCuisine(selectedCuisine !== name ? name : "")

  const styles = StyleSheet.create({
    container: {
      justifyContent: "center",
      borderRadius: 10
    },
    cuisineCard: {
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "center",
      width: isSmallScreen ? 40 : 50,
      height: isSmallScreen ? 40 : 50,
      borderRadius: isSmallScreen ? 20 : 25,
      marginHorizontal: isSmallScreen ? 5 : 15,
      marginTop: 5
    },
    detailsContainer: {
      padding: 5,
      alignItems: "center",
      justifyContent: "center"
    },
    name: {
      fontSize: 14,
      fontWeight: "500",
      fontFamily: Platform.OS === "android" ? "Roboto" : "Arial"
    },
    touchable: {
      marginBottom: 0,
      backgroundColor: colors.white
    }
  })

  return (
    <Hoverable>
      {isHovered => {
        const backgroundColor =
          name === selectedCuisine ? "#c0c4c4" : isHovered && "lightgrey"

        return (
          <View style={[styles.container, { backgroundColor }]}>
            <TouchableOpacity onPress={handleCuisineChange}>
              <View style={[styles.cuisineCard, { backgroundColor: color }]}>
                <Image
                  style={{
                    width: isSmallScreen ? 25 : 30,
                    height: isSmallScreen ? 25 : 30
                  }}
                  source={{ uri: makeFileServerAddress(icon, "8080") }}
                />
              </View>
              <View style={styles.detailsContainer}>
                <Text style={styles.name} numberOfLines={1}>
                  {name}
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        )
      }}
    </Hoverable>
  )
}

export default CuisineCard
