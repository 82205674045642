import styled from "styled-components/native"
import Image from "../Image"
import Text from "../Text"

import colors from "../../config/colors"

export const FooterWrapper = styled.View`
  background-color: ${colors.black};
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: -50px;
  margin-top: 16px;
`

export const Logo = styled(Image)`
  width: 50px;
  height: 25px;
  position: absolute;
  top: 0;
  left: 0;
  margin: 8px;
`

export const CopyrightText = styled(Text)`
  color: ${colors.white};
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 8px;
  font-size: 12px;
`

export const FooterList = styled.FlatList`
  align-self: center;
`
