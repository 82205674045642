import React, { useState } from "react"
import { View, StyleSheet, Alert, Platform } from "react-native"
import * as Google from "expo-google-app-auth"
import * as Facebook from "expo-facebook"
// import { TextInput } from "react-native-paper";

import TextInput from "../components/TextInput"
import Button from "../components/Button"
import userAccount from "../config/userAccount"
import colors from "../config/colors"
import Changeable from "../components/Changeable"
import Text from "../components/Text"
import {
  getModalDimensions,
  makeServerAddress,
  setLocationInfo,
  updateUserAddress
} from "../config/Globals"
import appStorage from "../utility/cache"
import JwtDecode from "jwt-decode"
import AuthContext from "../auth/context"
import RegistrationScreen from "./RegistrationScreen"
import Modal from "modal-enhanced-react-native-web"
import ConfirmCodeScreen from "./ConfirmCodeScreen"
import ErrorMessage from "../components/forms/ErrorMessage"
import { getCart } from "../config/Cart"

//Change the Welcome to logo?
//Add radio button for secureTextEntry for the user to see their password or not

function LoginScreen({
  navigation,
  loginModalVisible,
  setLoginModalVisible,
  checkingOut = false,
  atHomeScreen = false
}) {
  const { user, setUser, isSmallScreen, setCoordinates, coordinates } =
    React.useContext(AuthContext)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmCodeVisible, setConfirmCodeVisible] = React.useState(false)
  const [invalidEmailOrPassword, setInvalidEmailOrPassword] =
    React.useState(false)
  const [differentPasswords, setDifferentPasswords] = React.useState(false)
  const [invalidVerificationCode, setInvalidVerificationCode] =
    React.useState(false)
  const [forgotPasswordModalVisible, setForgotPasswordModalVisible] =
    React.useState(false)
  const [resetPasswordModalVisible, setResetPasswordModalVisible] =
    React.useState(false)
  const [registrationModalVisible, setRegistrationModalVisible] =
    useState(false)
  const { width, height, controlwidth } = getModalDimensions(isSmallScreen)
  const [validationCode, setValidationCode] = React.useState("")
  const [newPassword, setNewPassword] = React.useState("")
  const [confirmNewPassword, setConfirmNewPassword] = React.useState("")

  const checkDelivery = async () => {
    try {
      const response = await fetch(
        makeServerAddress(
          `/restaurant/canDeliver?restaurantId=${getCart().restaurantId}&lng=${
            coordinates.lng
          }&lat=${coordinates.lat}`
        )
      )
      if (response.status === 200) {
        const jsonData = await response.json()
        return jsonData.canDeliver
      } else return true
    } catch (err) {
      console.error(err)
    }
  }

  const resendCode = async () => {
    try {
      const response = await fetch(makeServerAddress("/user/sendmail"), {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: email
        })
      })
      if (response.status === 200) {
        Alert.alert("Code Sent", "Code successfully sent to your email", [
          { text: "Okay", style: "cancel" }
        ])
      } else {
        Alert.alert("Error", "Invalid login entry. Please try again.", [
          { text: "Okay", style: "cancel" }
        ])
      }
    } catch (err) {
      Alert.alert("Error", "Exception trying to resend code", [
        { text: "Okay", style: "cancel" }
      ])
      console.error(err.message)
    }
  }

  const resetPassword = async () => {
    try {
      if (newPassword !== confirmNewPassword) {
        setDifferentPasswords(true)
      } else {
        const response = await fetch(makeServerAddress("/user/resetpassword"), {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            email: email,
            newPassword: newPassword,
            vcode: validationCode
          })
        })
        if (response.status === 200) {
          setResetPasswordModalVisible(false)
          setLoginModalVisible(true)
        } else if (response.status === 500) {
          setInvalidVerificationCode(true)
        }
      }
    } catch (err) {
      console.error(err.message)
    }
  }

  const isUserAddress = async user => {
    try {
      const cacheAddress = await appStorage.get("address")

      if (cacheAddress) {
        var addr = JSON.parse(cacheAddress)
        await updateUserAddress(addr)
        return true
      }

      const response = await fetch(makeServerAddress(`/user/${user._id}`))
      const jsonData = await response.json()
      if ("address" in jsonData) {
        await setLocationInfo(
          {
            address: jsonData.address,
            coordinates: jsonData.location,
            location: jsonData.locationParts
          },
          null
        )
        setCoordinates(jsonData.location)
        return true
      } else {
        return false
      }
    } catch (err) {
      console.error(err.message)
      return false
    }
  }

  const handleLogin = async () => {
    try {
      const response = await fetch(makeServerAddress("/user/login"), {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: email,
          password: password
        })
      })

      const jsonData = await response.json()
      if (response.status === 200) {
        const tokenuser = JwtDecode(jsonData.token)
        setUser(tokenuser)
        await appStorage.store("token", jsonData.token)
        setLoginModalVisible(false)
        const isUserAddr = await isUserAddress(tokenuser)
        if (checkingOut) {
          const canDeliver = await checkDelivery()
          navigation.push("Cart", {
            canDeliver: canDeliver
          })
        } else if (
          typeof tokenuser.restaurantId !== "undefined" &&
          tokenuser.restaurantId.length > 0
        )
          navigation.push("RestaurantOrders")
        else if (atHomeScreen || isUserAddr) {
          navigation.push("Home")
        } else {
          navigation.push("Opening")
        }
      } else if (response.status === 500) {
        setLoginModalVisible(false)
        setConfirmCodeVisible(true)
      } else {
        setInvalidEmailOrPassword(true)
      }
    } catch (err) {
      console.error(err.message)
    }
  }

  async function signInWithGoogleAsync() {
    try {
      const result = await Google.logInAsync({
        androidClientId:
          "358236132701-m0tb6psetqe6tg1550c5pr6uc1ibo63r.apps.googleusercontent.com",
        iosClientId:
          "358236132701-hd25cl7m0qif78ceoouk24v0effldmus.apps.googleusercontent.com",
        scopes: ["profile", "email"]
      })

      if (result.type === "success") {
        //return result.accessToken;
        //console.log(result.user.photoUrl);
        if (userAccount.isCheckingOut == true) {
          navigation.push("Payment")
        } else {
          navigation.push("Home")
        }
      } else {
        //return { cancelled: true };
      }
    } catch (e) {
      //return { error: true };
    }
  }

  async function signInWithFacebookAsync() {
    try {
      await Facebook.initializeAsync("1004919936636148")
      const { type, token, expires, permissions, declinedPermissions } =
        await Facebook.logInWithReadPermissionsAsync({
          permissions: ["public_profile"]
        })
      if (type === "success") {
        // Get the user's name using Facebook's Graph API
        const response = await fetch(
          `https://graph.facebook.com/me?access_token=${token}&fields=id,name,email,picture.height(500)`
        )
        if (userAccount.isCheckingOut == true) {
          navigation.push("Payment")
        } else {
          navigation.push("Opening")
        }
        //Alert.alert('Logged in!', `Hi ${(await response.json()).name}!`);
      } else {
        //type === 'cancel'
      }
    } catch ({ message }) {
      //alert(`Facebook Login Error: ${message}`);
    }
  }

  const styles = StyleSheet.create({
    screen: {
      flex: 1,
      backgroundColor: colors.light
    },
    container: {
      padding: Platform.OS === "web" ? 0 : 10,
      alignItems: "center"
    },
    input: {
      padding: 30
    },
    buttonText: {
      fontSize: 22
    },
    buttonContainer: {
      width: controlwidth,
      height: isSmallScreen ? 40 : 50,
      marginVertical: 5
    },
    loginText: {
      fontSize: 30,
      textAlign: "center"
    },
    loginTextContainer: {
      paddingBottom: 50,
      paddingTop: 50
    },
    iconButton: {
      height: isSmallScreen ? 40 : 50,
      width: controlwidth
    }
  })

  return (
    <>
      <Modal
        isVisible={loginModalVisible}
        onBackdropPress={() => setLoginModalVisible(false)}
        animationIn="zoomIn"
        animationOut="zoomOut"
      >
        <View
          style={{
            width: width,
            height: isSmallScreen ? height - 40 : height / 2,
            minHeight: 500,
            minWidth: 300,
            alignSelf: "center",
            justifyContent: "center",
            backgroundColor: colors.white,
            shadowOffset: { width: 1, height: 1 },
            shadowOpacity: 0.5,
            shadowColor: colors.medium,
            shadowRadius: 2,
            borderRadius: 10
          }}
        >
          {checkingOut && (
            <View style={{ alignItems: "center" }}>
              <View style={styles.container}>
                <Changeable
                  title="Create Account"
                  stylesText={{
                    fontSize: isSmallScreen ? 18 : 22,
                    fontWeight: "bold"
                  }}
                  onPress={() => {
                    setLoginModalVisible(false)
                    setRegistrationModalVisible(true)
                  }}
                />
              </View>
              <Text
                style={{
                  fontFamily: Platform.OS === "android" ? "Roboto" : "Arial",
                  paddingBottom: 15
                }}
              >
                or
              </Text>
            </View>
          )}
          <View style={{ alignItems: "center" }}>
            <Text
              style={{
                fontWeight: "bold",
                // fontFamily: Platform.OS === "android" ? "Roboto" : "Arial",
                fontSize: isSmallScreen ? 18 : 22,
                paddingBottom: isSmallScreen ? 15 : "5%"
              }}
            >
              Login to your account
            </Text>
          </View>
          <View style={{ alignItems: "center" }}>
            <TextInput
              label="Email"
              style={{
                height: isSmallScreen ? 25 : 20,
                width: controlwidth,
                marginBottom: 50
              }}
              value={email}
              autoCompleteType="email"
              onChangeText={value => setEmail(value)}
            />

            <TextInput
              label="Password"
              style={{
                height: isSmallScreen ? 25 : 20,
                width: controlwidth,
                marginBottom: 50
              }}
              autoCompleteType="password"
              blurOnSubmit
              secureTextEntry={true}
              onChangeText={value => setPassword(value)}
            />
            <View style={{ alignSelf: "flex-start", paddingLeft: 20 }}>
              <ErrorMessage
                error="Invalid email or password"
                style={{ fontSize: 14 }}
                visible={invalidEmailOrPassword}
              />
            </View>
            <View>
              <Changeable
                title="Forgot Password?"
                onPress={() => {
                  setLoginModalVisible(false)
                  setForgotPasswordModalVisible(true)
                }}
              />
            </View>
            <View style={styles.container}>
              <Button
                title="Login"
                styleButton={styles.buttonContainer}
                styleText={styles.buttonText}
                onPress={async () => await handleLogin()}
              />
            </View>
          </View>
          {!checkingOut && (
            <View style={{ alignItems: "center" }}>
              <Text
                style={{
                  fontFamily: Platform.OS === "android" ? "Roboto" : "Arial"
                }}
              >
                or
              </Text>
              <View style={styles.container}>
                <Changeable
                  title="Create Account"
                  stylesText={{
                    fontSize: isSmallScreen ? 18 : 22,
                    fontWeight: "bold"
                  }}
                  onPress={() => {
                    setLoginModalVisible(false)
                    setRegistrationModalVisible(true)
                  }}
                />
              </View>
            </View>
          )}
        </View>
      </Modal>

      <RegistrationScreen
        registrationModalVisible={registrationModalVisible}
        setRegistrationModalVisible={setRegistrationModalVisible}
      />
      <ConfirmCodeScreen
        navigation={navigation}
        confirmCodeVisible={confirmCodeVisible}
        setConfirmCodeVisible={setConfirmCodeVisible}
        email={email}
        password={password}
      />

      <Modal
        isVisible={forgotPasswordModalVisible}
        onBackdropPress={() => setForgotPasswordModalVisible(false)}
        animationIn="zoomIn"
        animationOut="zoomOut"
      >
        <View
          style={{
            width: width,
            height: isSmallScreen ? height - 40 : height / 2,
            minHeight: 500,
            minWidth: 300,
            alignSelf: "center",
            justifyContent: "center",
            backgroundColor: colors.white,
            shadowOffset: { width: 1, height: 1 },
            shadowOpacity: 0.5,
            shadowColor: colors.medium,
            shadowRadius: 2,
            borderRadius: 10
          }}
        >
          <View style={{ alignItems: "center" }}>
            <Text
              style={{
                fontWeight: "bold",
                // fontFamily: Platform.OS === "android" ? "Roboto" : "Arial",
                fontSize: isSmallScreen ? 18 : 22,
                paddingBottom: isSmallScreen ? 15 : "5%"
              }}
            >
              Enter your email
            </Text>
          </View>
          <View style={{ alignItems: "center" }}>
            <TextInput
              label="Email"
              style={{
                height: isSmallScreen ? 25 : 20,
                width: controlwidth,
                marginBottom: 50
              }}
              value={email}
              autoCompleteType="email"
              onChangeText={value => setEmail(value)}
            />
            <View style={styles.container}>
              <Button
                title="Reset Password"
                styleButton={styles.buttonContainer}
                styleText={styles.buttonText}
                onPress={async () => {
                  await resendCode()
                  setForgotPasswordModalVisible(false)
                  setResetPasswordModalVisible(true)
                }}
              />
            </View>
          </View>
        </View>
      </Modal>

      <Modal
        isVisible={resetPasswordModalVisible}
        onBackdropPress={() => setResetPasswordModalVisible(false)}
        animationIn="zoomIn"
        animationOut="zoomOut"
      >
        <View
          style={{
            width: width,
            height: isSmallScreen ? height - 40 : height / 2,
            minHeight: 500,
            minWidth: 300,
            alignSelf: "center",
            justifyContent: "center",
            backgroundColor: colors.white,
            shadowOffset: { width: 1, height: 1 },
            shadowOpacity: 0.5,
            shadowColor: colors.medium,
            shadowRadius: 2,
            borderRadius: 10
          }}
        >
          <View style={{ alignItems: "center" }}>
            <Text
              style={{
                // fontFamily: Platform.OS === "android" ? "Roboto" : "Arial",
                fontSize: isSmallScreen ? 14 : 18,
                paddingBottom: isSmallScreen ? 15 : "5%",
                textAlign: "center"
              }}
            >
              Validation code has been sent to email adddress: {email}. Enter
              validation code and new password below:
            </Text>
          </View>
          <View style={{ alignItems: "center" }}>
            <TextInput
              label="Verify Code"
              style={{
                height: isSmallScreen ? 25 : 20,
                width: controlwidth,
                marginBottom: 50
              }}
              value={validationCode}
              onChangeText={value => setValidationCode(value)}
            />
            <View
              style={{
                alignSelf: "flex-start",
                paddingLeft: 20
              }}
            >
              <ErrorMessage
                error="Invalid verification code"
                style={{ fontSize: 14 }}
                visible={invalidVerificationCode}
              />
            </View>
            <TextInput
              label="New Password"
              style={{
                height: isSmallScreen ? 25 : 20,
                width: controlwidth,
                marginBottom: 50
              }}
              value={newPassword}
              autoCompleteType="password"
              blurOnSubmit
              secureTextEntry={true}
              onChangeText={value => setNewPassword(value)}
            />
            <TextInput
              label="Confirm New Password"
              style={{
                height: isSmallScreen ? 25 : 20,
                width: controlwidth,
                marginBottom: 50
              }}
              value={confirmNewPassword}
              autoCompleteType="password"
              blurOnSubmit
              secureTextEntry={true}
              onChangeText={value => setConfirmNewPassword(value)}
            />
            <View
              style={{
                alignSelf: "flex-start",
                paddingLeft: 20,
                paddingBottom: 10
              }}
            >
              <ErrorMessage
                error="Passwords are not matching"
                style={{ fontSize: 14 }}
                visible={differentPasswords}
              />
            </View>
            <View style={styles.container}>
              <Button
                title="Change Password"
                styleButton={styles.buttonContainer}
                styleText={styles.buttonText}
                onPress={async () => await resetPassword()}
              />
            </View>
          </View>
        </View>
      </Modal>
    </>
  )
}

export default LoginScreen
