import React, { useState } from "react"
import {
  View,
  Text,
  StyleSheet,
  Platform,
  FlatList,
  Dimensions
} from "react-native"
import { v4 as uuidv4 } from "react-native-uuid"

import AuthContext from "../../auth/context"
import {
  getCart,
  getSubtotal,
  removeItem,
  setCartItemsGlobal
} from "../../config/Cart"
import colors from "../../config/colors"
import DeleteCartItemButton from "./DeleteCartItemButton"

export default function CartList({ subTotal, setSubtotal }) {
  const { numItems, setNumItems, isSmallScreen } = React.useContext(AuthContext)

  const styles = StyleSheet.create({
    screen: {
      flex: 1
    },
    restaurantName: {
      padding: 10
    },
    restaurantNameText: {
      fontSize: 24,
      fontWeight: "bold"
    },
    cartItem: {
      flexDirection: "row",
      paddingTop: 10,
      paddingLeft: 10,
      paddingRight: 10,
      justifyContent: "space-between"
    },
    deliveryTimeText: {
      fontSize: 16
    },
    header: {
      alignItems: "center",
      top: 10,
      paddingTop: Platform.OS === "ios" ? 40 : 30
    },
    changable: {
      fontSize: 14,
      fontWeight: "bold",
      fontFamily:
        Platform.OS === "android" ? "sans-serif-medium" : "Avenir-Medium"
    },
    totals: {
      position: "absolute",
      bottom: isSmallScreen ? "5%" : "7%",
      right: 0,
      left: 0,
      flex: 1,
      paddingTop: 2,
      paddingBottom: 2,
      paddingRight: 15,
      paddingLeft: 15
    },
    totalsContainer: {
      flexDirection: "row",
      padding: 5,
      paddingLeft: 10,
      paddingRight: 10,
      justifyContent: "space-between"
    }
  })

  let cart = getCart()
  let fee = subTotal * 0.05
  let total = subTotal * 1.05

  // React.useEffect(() => {
  //   cart = getCart();
  //   setCartItems(cart.items);
  // }, []);

  if (cart.numItems !== 0) {
    return (
      <View
        style={{
          alignItems: "center",
          flex: 1,
          paddingTop: 20
        }}
      >
        <View style={{ alignSelf: "flex-start" }}>
          <Text
            style={{ paddingLeft: 30, fontSize: 20, color: colors.skyblue }}
          >
            {cart.restaurantName}
          </Text>
        </View>
        <View
          style={{
            width: isSmallScreen ? Dimensions.get("window").width - 60 : 360,
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <Text style={{ fontWeight: "bold", fontSize: 20 }}>Qty</Text>
          <Text style={{ fontWeight: "bold", fontSize: 20 }}>Item</Text>
          <Text style={{ fontWeight: "bold", fontSize: 20 }}>Price</Text>
          <Text style={{ fontWeight: "bold", fontSize: 20 }}>Delete</Text>
        </View>
        <View style={{ marginBottom: 140, flex: 1 }}>
          <FlatList
            data={getCart().items}
            keyExtractor={() => uuidv4()}
            showsVerticalScrollIndicator={false}
            renderItem={({ item }) => (
              <View
                style={{ paddingRight: 30, paddingLeft: 30, paddingTop: 10 }}
              >
                <View
                  style={{
                    width: isSmallScreen
                      ? Dimensions.get("window").width - 60
                      : 360,
                    flexDirection: "row",
                    justifyContent: "space-between"
                  }}
                >
                  <Text style={{ fontSize: 16 }}>{item.quantity}</Text>
                  <Text style={{ fontSize: 16 }}>{item.itemName}</Text>
                  <Text style={{ fontSize: 16 }}>${item.price.toFixed(2)}</Text>
                  <DeleteCartItemButton
                    onPress={async () => {
                      await removeItem(item)
                      // setCartItems(newData);
                      // setCartItemsGlobal(newData)
                      setSubtotal(getSubtotal())
                    }}
                  />
                </View>
                {item.options.length > 0 && (
                  <View style={{ paddingLeft: 10 }}>
                    <Text>{item.options}</Text>
                  </View>
                )}
                {item.specialInstructions.length > 0 && (
                  <View style={{ paddingLeft: 10 }}>
                    <Text>{item.specialInstructions}</Text>
                  </View>
                )}
              </View>
            )}
          />
        </View>
        <View style={styles.totals}>
          <View>
            <View style={styles.totalsContainer}>
              <Text>Items subtotal:</Text>
              <Text>${subTotal.toFixed(2)}</Text>
            </View>
            <View style={styles.totalsContainer}>
              <Text>Tax and service fee:</Text>
              <Text>${fee.toFixed(2)}</Text>
            </View>
            <View style={styles.totalsContainer}>
              <Text>Total:</Text>
              <Text>${total.toFixed(2)}</Text>
            </View>
          </View>
        </View>
      </View>
    )
  } else {
    return (
      <View style={{ alignSelf: "center", flex: 1, paddingTop: 20 }}>
        <View>
          <Text>Your Cart is Empty</Text>
        </View>
      </View>
    )
  }
}
