import styled from "styled-components/native"
import Text from "../Text"

export const LoadingIcon = styled.ActivityIndicator`
  margin: 0px 10px;
`

export const LoadingText = styled(Text)`
  margin: 10px;
`
