import { v4 as uuidV4 } from "react-native-uuid"

import appStorage from "../utility/cache"
import { getAddress, getLocationInfo } from "./Globals"

let cart = {
  userName: "",
  restaurantName: "",
  restaurantId: "",
  idempotencyKey: "",
  address: "",
  numItems: 0,
  deliveryTime: 0,
  isDelivery: true,
  payOnline: true,
  items: [{ id: "", quantity: 1, name: "", price: 0.0, options: [] }],
  subtotal: 0.0,
  fee: 0.0,
  total: 0.0
}

async function addItem(newItem) {
  if (cart.numItems === 0) {
    cart.restaurantId = newItem.restaurantId
    cart.restaurantName = newItem.restaurantName
    cart.relayProducerKey = newItem.relayProducerKey
    cart.idempotencyKey = uuidV4()
  }
  cart.items[cart.numItems] = newItem
  cart.items[cart.numItems].id = uuidV4()
  ++cart.numItems
  cart.subtotal += newItem.price
  cart.fee = parseFloat(cart.subtotal * 0.05).toFixed(2)
  cart.tip = "0.00"
  cart.total = parseFloat(cart.subtotal * 1.05).toFixed(2)
  await appStorage.store("cart", cart)
}

async function removeItem(item) {
  --cart.numItems
  cart.subtotal -= item.price
  cart.total = parseFloat(cart.subtotal * 1.05).toFixed(2)
  const newItems = cart.items.filter(data => {
    return data.id !== item.id
  })
  cart.items = newItems
  if (cart.numItems == 0) {
    await clearCart()
  } else {
    await appStorage.store("cart", cart)
  }
}

function findItemByName(name) {
  for (let i = 0; i < cart.items.length; i++) {
    if (cart.items[i].itemName === name)
      return true
  }
  return false
}

async function setCartItemsGlobal(cartItems) {
  cart.items = cartItems
  cart.numItems = cartItems.length
  await appStorage.store("cart", cart)
}

function getSubtotal() {
  return cart.subtotal
}

function getCart(name) {
  cart.address = getAddress()
  cart.userName = name
  cart.locationParts = getLocationInfo().location
  return cart
}

async function setCart(newCart) {
  cart = newCart
}

async function clearCart() {
  cart.restaurantName = ""
  cart.restaurantId = ""
  cart.idempotencyKey = ""

  cart.numItems = 0
  cart.items = []
  resetPrice()
  await appStorage.remove("cart")
}

function resetPrice() {
  cart.subtotal = 0.0
  cart.total = 0.0
  cart.fee = 0.0
}

function updateIsDelivery(isDelivery) {
  cart.isDelivery = isDelivery
}

function getIsDelivery() {
  return cart.isDelivery
}

function updatePayOnline(payOnline) {
  cart.payOnline = payOnline
}

function getPayOnline() {
  return cart.payOnline
}

function getNumCartItems() {
  return cart.numItems
}

function isSameRestaurant(restaurantId) {
  if (cart.restaurantId === "") return true
  return cart.restaurantId === restaurantId ? true : false
}

async function replaceCart(newItem) {
  await clearCart()
  await addItem(newItem)
}

export {
  addItem,
  getCart,
  setCart,
  clearCart,
  getSubtotal,
  updateIsDelivery,
  getIsDelivery,
  setCartItemsGlobal,
  getNumCartItems,
  removeItem,
  updatePayOnline,
  getPayOnline,
  isSameRestaurant,
  replaceCart,
  findItemByName
}
