import React, { Component } from "react"
import {
  View,
  Text,
  StyleSheet,
  Platform,
  FlatList,
  TouchableOpacity
} from "react-native"

import colors from "../config/colors"
import ItemSeparator from "../components/restaurant/ItemSeparator"
import userAccount from "../config/userAccount"
import Button from "../components/Button"
import StripeCheckout from "react-stripe-checkout"

class PaymentScreeen extends Component {
  constructor(props) {
    super(props)
  }
  state = {
    OSPayment: Platform.OS == "ios" ? "Apple Pay" : "Android Pay",
    creditCard: userAccount.creditCard
  }

  componentDidMount() {
    this._unsubscribe = this.props.navigation.addListener("focus", () => {
      if (userAccount.needUpdate) {
        this.setState({ creditCard: userAccount.creditCard })
        userAccount.needUpdate = false
      }
    })
  }

  componentWillUnmount() {
    this._unsubscribe()
  }

  render() {
    const { navigation } = this.props

    async function handleToken(token, addresses) {
      var product = {
        name: "WSE Food",
        price: 24.67,
        description: "Restaurant 1"
      }
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token,
          product: {
            name: "WSE Food",
            price: 24.67,
            description: "Restaurant 1"
          }
        })
      }
      const response = await fetch(
        makeServerAddress("/payment"),
        requestOptions
      )
      // const { status } = response.data;
      // console.log("Response:", response.data);
      // if (status === "success") {
      //   toast("Success! Check email for details", { type: "success" });
      // } else {
      //   toast("Something went wrong", { type: "error" });
      // }
    }

    return (
      <View style={styles.screen}>
        <View style={styles.header}>
          <Text style={styles.changable}>Payment</Text>
          <ItemSeparator />
        </View>
        <View>
          <View style={styles.paymentHeader}>
            <Text style={styles.options}>Payment Method</Text>
          </View>
          <View style={styles.optionsContainer}>
            <TouchableOpacity onPress={() => {}}>
              <Text style={styles.options}>{this.state.OSPayment}</Text>
            </TouchableOpacity>
          </View>
          <ItemSeparator />
          <View style={styles.optionsContainer}>
            <TouchableOpacity onPress={() => {}}>
              <Text style={styles.options}>Paypal</Text>
            </TouchableOpacity>
          </View>
          <ItemSeparator />
          <FlatList
            data={this.state.creditCard}
            keyExtractor={item => item.id.toString()}
            extraData={(this.state.creditCard, userAccount.creditCard)}
            renderItem={({ item }) => (
              <View>
                <View style={styles.optionsContainer}>
                  <TouchableOpacity onPress={() => {}}>
                    <Text style={styles.options}>{item.hiddenNum}</Text>
                  </TouchableOpacity>
                </View>
                <ItemSeparator />
              </View>
            )}
          />
          <View style={styles.optionsContainer}>
            <TouchableOpacity
              onPress={() => {
                navigation.navigate("AddNewPayment")
              }}
            >
              <Text style={styles.addMethod}>Add Payment Method</Text>
            </TouchableOpacity>
          </View>
          {Platform.OS === "web" && (
            <View style={{ alignSelf: "center" }}>
              <StripeCheckout
                // stripeKey="pk_test_51HSCMyL8XhRvlltMXRFTkJmNGqQ0DSyWusugFq9rqoN7jyiIEqSFRSuskEHmnWQYRxclzfLg8QqDZNqQOUq1Nz4H00MK2ag36I"
                stripeKey="pk_live_51IMFdhLHAeGvlqtqyzDuUNi1Y7jVYivh4UWm51XKmB71LeMKCwc5zlilyqJio6kbMqis8YALdwt3IzqeMmF2qVU100hBEtElpV"
                token={handleToken}
                style={{ width: 200 }}
                amount={24.67 * 100}
                name={this.state.product}
                billingAddress
              />
            </View>
          )}

          <View style={styles.nextScreenButtonContainer}>
            <Button
              title="Continue to Delivery Options"
              color="yellow"
              styleButton={styles.nextScreenButton}
              onPress={() => navigation.push("DeliveryOptions")}
            />
          </View>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  screen: {
    flex: 1
  },
  header: {
    alignItems: "center",
    top: 10,
    paddingTop: Platform.OS === "ios" ? 40 : 30
  },
  changable: {
    fontSize: 14,
    fontWeight: "bold",
    fontFamily:
      Platform.OS === "android" ? "sans-serif-medium" : "Avenir-Medium"
  },
  options: {
    fontSize: 16,
    fontWeight: "bold"
  },
  optionsContainer: {
    paddingLeft: 35
  },
  addMethod: {
    color: colors.yellow,
    fontSize: 16,
    fontWeight: "bold"
  },
  paymentHeader: {
    padding: 10
  },
  nextScreenButton: {
    width: "80%",
    height: 50,
    top: 20
  },
  nextScreenButtonContainer: {
    alignItems: "center"
  }
})

export default PaymentScreeen
