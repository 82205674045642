import React, { useContext } from "react"
import { StyleSheet } from "react-native"

import AuthContext from "../../auth/context"
import colors from "../../config/colors"
import Text from "../Text"

export const FooterText = ({ style, children, ...props }) => {
  const { isSmallScreen } = useContext(AuthContext)

  return (
    <Text
      style={[
        styles.footerText,
        isSmallScreen && { marginHorizontal: 4, fontSize: 12 },
        style
      ]}
      {...props}
    >
      {children}
    </Text>
  )
}

const styles = StyleSheet.create({
  footerText: {
    color: colors.white,
    alignSelf: "center",
    margin: 8,
    fontSize: 14
  }
})
