import React, { Fragment } from "react"
import * as SC from "./styles"

import colors from "../../config/colors"

const LoadingIndicator = ({ title, size, color, ...props }) => {
  return (
    <Fragment>
      <SC.LoadingIcon size={size} color={colors[color]} {...props} />
      {title && <SC.LoadingText>{title}</SC.LoadingText>}
    </Fragment>
  )
}

export default LoadingIndicator
