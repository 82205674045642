import React from "react"
import { View } from "react-native"
import { useFormikContext } from "formik"

import TextInput from "../TextInput"
import ErrorMessage from "./ErrorMessage"

function AppFormField({ name, errorStyle, ...props }) {
  const { setFieldTouched, handleChange, errors, touched } = useFormikContext()

  return (
    <View style={{ alignItems: "flex-start" }}>
      <TextInput
        onBlur={() => setFieldTouched(name)}
        onChangeText={handleChange(name)}
        {...props}
      />
      <ErrorMessage
        error={errors[name]}
        visible={touched[name]}
        style={errorStyle}
      />
    </View>
  )
}

export default AppFormField
