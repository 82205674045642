import React, { useState, useContext, useEffect, Fragment } from "react"
import { FlatList, View, StyleSheet, Platform } from "react-native"
import { ScrollView } from "react-native-web"

import AuthContext from "../auth/context"
import {
  makeServerAddress,
  getHeaderHeight,
  setCurrentRestaurants
} from "../config/Globals"
import AddressTag from "../components/AddressTag"
import Footer from "../components/Footer"
import ListFilter from "../components/ListFilter"
import Loading from "../components/Loading"
import LoadingIndicator from "../components/LoadingIndicator"
import RestaurantCard from "../components/restaurant/RestaurantCard"
import RestaurantClosedScreen from "./RestaurantClosedScreen"
import SEO from "../components/SEO"
import Slogan from "../components/Slogan"
import Text from "../components/Text"

const HomeScreen = ({ navigation }) => {
  const { selectedCuisine, coordinates, isDelivery } = useContext(AuthContext)
  const [restaurants, setRestaurants] = useState(null)
  const [cuisines, setCuisines] = useState(null)
  const [closedModalVisible, setClosedModalVisible] = useState(false)
  const [restaurantHours, setRestaurantHours] = useState()
  const filteredRestaurants =
    selectedCuisine && restaurants
      ? restaurants.filter(restaurant => restaurant.cuisine === selectedCuisine)
      : restaurants

  const getRestaurants = async () => {
    try {
      const response = await fetch(
        makeServerAddress(
          `/restaurants?lng=${coordinates.lng}&lat=${coordinates.lat}&isDelivery=${isDelivery}`
        )
      )
      const jsonData = await response.json()
      setRestaurants(jsonData)
      setCurrentRestaurants(jsonData)
    } catch (err) {
      console.error(err.message)
    }
  }

  const getCuisines = async () => {
    try {
      const response = await fetch(makeServerAddress("/cuisines"))
      const jsonData = await response.json()
      setCuisines(jsonData)
    } catch (err) {
      console.error(err.messgae)
    }
  }

  const handleRestaurantSelect = item => {
    if (!item.isOpen) {
      setClosedModalVisible(true)
      setRestaurantHours(item.time)
    } else {
      navigation.push("Restaurant", {
        restaurantId: item._id,
        restaurantName: item.name,
        menuId: item.menuId,
        relayProducerKey: item.relayProducerKey
      })
    }
  }

  useEffect(() => {
    getRestaurants()
    getCuisines()
  }, [coordinates, isDelivery])

  return (
    <Fragment>
      <SEO title="Home" />
      <View style={styles.screen}>
        {Platform.OS !== "web" && <AddressTag />}
        <Slogan type="home" />
        <ListFilter cuisines={cuisines} />
        <Loading
          data={[restaurants, cuisines]}
          indicator={<LoadingIndicator size="large" color="wseorange" />}
        >
          {filteredRestaurants?.length ? (
            <ScrollView style={styles.feed}>
              <FlatList
                showsVerticalScrollIndicator={false}
                data={filteredRestaurants}
                keyExtractor={restaurantCard => restaurantCard._id}
                renderItem={({ item }) => (
                  <RestaurantCard
                    name={item.name}
                    cuisine={item.cuisine}
                    image1={item.image1}
                    image2={item.image2}
                    isOpen={item.isOpen}
                    onPress={() => handleRestaurantSelect(item)}
                  />
                )}
              />
            </ScrollView>
          ) : (
            <Text style={styles.noRestaurantsText}>
              No {selectedCuisine ? `${selectedCuisine} ` : ""}restaurants found
              in your area for {isDelivery ? "delivery" : "pickup"}
            </Text>
          )}
        </Loading>
      </View>
      <RestaurantClosedScreen
        closedModalVisible={closedModalVisible}
        setClosedModalVisible={setClosedModalVisible}
        hours={restaurantHours}
      />
      {Platform.OS === "web" && <Footer />}
    </Fragment>
  )
}

const styles = StyleSheet.create({
  feed: {
    width: "100%"
  },
  noRestaurantsText: {
    marginTop: 10,
    marginBottom: 100
  },
  screen: {
    flex: 1,
    width: "100%",
    maxWidth: 700,
    alignItems: "center",
    alignSelf: "center",
    marginTop: getHeaderHeight()
  }
})

export default HomeScreen
