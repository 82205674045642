import React from "react"
import { useFormikContext } from "formik"

import Button from "../Button"

function SubmitButton({ title, styleButton, styleText }) {
  const { handleSubmit } = useFormikContext()

  return (
    <Button
      title={title}
      onPress={handleSubmit}
      styleButton={styleButton}
      styleText={styleText}
    />
  )
}

export default SubmitButton
