import React from "react"
import { Animated } from "react-native"

import Text from "../Text"

const AnimatedText = ({ animation, children, ...props }) => {
  return (
    <Animated.View style={{ opacity: animation }}>
      <Text {...props}>{children}</Text>
    </Animated.View>
  )
}

export default AnimatedText
