import React from "react"
import { StyleSheet, Text, TouchableOpacity } from "react-native"
import { useFormikContext } from "formik"

import colors from "../config/colors"

const Button = ({
  title,
  onPress,
  color = "medium",
  styleText,
  styleButton,
  submit = false,
  isSmallScreen = false,
  disabled = false,
  children
}) => {
  const styles = StyleSheet.create({
    button: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      marginVertical: 10,
      padding: 15,
      borderRadius: 25
    },
    text: {
      color: colors.white,
      fontSize: isSmallScreen ? 12 : 18,
      fontWeight: "bold"
    }
  })

  return (
    <TouchableOpacity
      style={[styles.button, { backgroundColor: colors[color] }, styleButton]}
      onPress={!submit ? onPress : useFormikContext().handleSubmit}
      disabled={disabled}
    >
      <Text style={[styles.text, styleText]}>{title}</Text>
      {children}
    </TouchableOpacity>
  )
}

export default Button
