import React, { useState, useContext, useEffect, Fragment } from "react"
import { View, FlatList, Dimensions, StyleSheet } from "react-native"
import { v4 as uuidV4 } from "react-native-uuid"
import { Audio } from "expo-av"

import AuthContext from "../auth/context"
import appStorage from "../utility/cache"
import colors from "../config/colors"
import {
  makeServerAddress,
  makeFileServerAddress,
  signOut
} from "../config/Globals"
import Button from "../components/Button"
import OrderStatusButton from "../components/OrderStatusButton"
import OrderTag from "../components/restaurant/OrderTag"
import SEO from "../components/SEO"
import Text from "../components/Text"

function RestaurantOrderScreen({ navigation }) {
  const { user, setUser } = useContext(AuthContext)
  const [orders, setOrders] = useState([])
  const [clickedOrder, setClickedOrder] = useState([])
  const [orderClicked, setOrderClicked] = useState(false)
  const [orderTime, setOrderTime] = useState("")
  const dimensions = Dimensions.get("window")
  const ticketWidth = dimensions.width * 0.45

  const showOrder = (orderId, time) => {
    const selectedOrder = orders.find(order => order._id == orderId)
    setOrderTime(time)
    setClickedOrder(selectedOrder)
    setOrderClicked(true)
  }

  const getRestaurantOrders = async () => {
    try {
      const authtoken = await appStorage.get("token")
      const response = await fetch(makeServerAddress("/restaurant/orders"), {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ token: authtoken })
      })
      const loadedOrders = await response.json()
      setOrders(loadedOrders)

      const newOrder = loadedOrders.some(order => order.status === "New")
      if (newOrder) {
        const { sound } = await Audio.Sound.createAsync({
          uri: makeFileServerAddress("/neworder.mp3", "8080")
        })
        await sound.playAsync()
      }
    } catch (err) {
      console.error(err.message)
    }
  }

  function MyText({ children }) {
    return <Text style={{ fontSize: 14 }}>{children}</Text>
  }
  useEffect(() => {
    getRestaurantOrders()
    const timer = setInterval(() => getRestaurantOrders(), 30000)

    return () => clearInterval(timer)
  }, [])

  const styles = StyleSheet.create({
    ticket: {
      width: ticketWidth,
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: 10
    }
  })

  return (
    <Fragment>
      <SEO title="Restaurant Orders" />
      <View
        style={{ alignItems: "center", flex: 1, backgroundColor: colors.light }}
      >
        <View
          style={{
            width: dimensions.width * 0.9,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Text style={{ fontSize: 30, fontWeight: "bold" }}>
            Hi, {user !== null ? user.name : ""}
          </Text>
          <Button
            title="Sign Out"
            onPress={async () => {
              await signOut(true)
              navigation.navigate("Opening")
              setUser(null)
            }}
          />
        </View>
        <View
          style={{
            justifyContent: "center",
            marginTop: dimensions.height * 0.02,
            flexDirection: "row"
          }}
        >
          <View style={{ height: dimensions.height * 0.6 }}>
            <FlatList
              data={orders}
              keyExtractor={order => order._id}
              showsVerticalScrollIndicator={false}
              renderItem={({ item }) => (
                <OrderTag
                  order={item}
                  onPress={showOrder}
                  width={dimensions.width * 0.25}
                />
              )}
            />
          </View>
          <View
            style={{
              backgroundColor: colors.white,
              alignItems: "flex-start",
              marginLeft: dimensions.width * 0.04,
              width: ticketWidth,
              borderRadius: 5,
              height: dimensions.height * 0.8
            }}
          >
            {orders.length <= 0 && (
              <View
                style={{
                  height: "500",
                  alignItems: "center",
                  alignContent: "center"
                }}
              >
                <Text>There are no orders today</Text>
              </View>
            )}
            {orderClicked && (
              <View>
                <OrderStatusButton
                  orderId={clickedOrder._id}
                  orders={orders}
                  setOrders={setOrders}
                  buttonWidth={ticketWidth / 3 - 15}
                />
                <View
                  style={{
                    backgroundColor: colors.lightgrey,
                    marginBottom: 20,
                    padding: 10
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between"
                    }}
                  >
                    <MyText>
                      {(clickedOrder.order.isDelivery ? "Delivery" : "Pickup") +
                        ` for ${clickedOrder.order.userName}`}
                    </MyText>
                    <MyText>{orderTime}</MyText>
                  </View>

                  <View>
                    {clickedOrder.order.isDelivery && (
                      <MyText>
                        {clickedOrder.order.locationParts.address1 +
                          ", " +
                          clickedOrder.order.locationParts.city +
                          " " +
                          clickedOrder.order.locationParts.zip}
                      </MyText>
                    )}
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between"
                      }}
                    >
                      <MyText>{"Phone No: " + clickedOrder.phonenumber}</MyText>
                      <MyText>
                        {"Payment: " +
                          (clickedOrder.order.isDelivery ||
                          clickedOrder.order.payOnline
                            ? "Paid Online"
                            : "Pay In Store")}
                      </MyText>
                      {/* <MyText>{"Relay Key: " + clickedOrder.relayOrderId}</MyText> */}
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    justifyContent: "space-between",
                    height: dimensions.height * 0.55
                  }}
                >
                  <View
                    style={{ flex: 1, marginBottom: 10, flexDirection: "row" }}
                  >
                    <FlatList
                      data={clickedOrder.order.items}
                      keyExtractor={() => uuidV4()}
                      renderItem={({ item }) => (
                        <View style={{ marginBottom: 15 }}>
                          <View style={styles.ticket}>
                            <MyText>{item.quantity}</MyText>
                            <View>
                              <MyText>{item.itemName}</MyText>
                              {"options" in item && item.options.length > 0 && (
                                <MyText>{item.options}</MyText>
                              )}
                              {"specialInstructions" in item &&
                                item.specialInstructions.length > 0 && (
                                  <MyText>{item.specialInstructions}</MyText>
                                )}
                            </View>
                            <MyText>${item.price.toFixed(2)}</MyText>
                          </View>
                        </View>
                      )}
                    />
                  </View>
                  <View
                    style={{
                      alignItems: "flex-end",
                      marginRight: 20,
                      marginBottom: 35
                    }}
                  >
                    {clickedOrder.order.discount > 0 && (
                      <MyText>
                        Discount: -${clickedOrder.order.discount.toFixed(2)}
                      </MyText>
                    )}
                    <MyText>
                      Subtotal: ${clickedOrder.order.subtotal.toFixed(2)}
                    </MyText>
                    <MyText>
                      Fee: Fee: ${clickedOrder.order.fee.toFixed(2)}
                    </MyText>
                    {clickedOrder.order.isDelivery && (
                      <MyText>
                        Delivery Fee: $
                        {clickedOrder.order.deliveryFee.toFixed(2)}
                      </MyText>
                    )}
                    <MyText>Tip: ${clickedOrder.order.tip.toFixed(2)}</MyText>
                    <MyText>
                      Total: ${clickedOrder.order.total.toFixed(2)}
                    </MyText>
                  </View>
                </View>
              </View>
            )}
          </View>
        </View>
      </View>
    </Fragment>
  )
}

export default RestaurantOrderScreen
