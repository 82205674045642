import { Platform, Dimensions } from "react-native"

import appStorage from "../utility/cache"
import { stripeProd, stripeTest } from "../auth/keys.json"
import axios from "axios"

const inProduction = process.env.NODE_ENV === "production"
const inDevelopment = process.env.NODE_ENV === "development"
const isCharlotte = window.location.hostname !== "westsideeats.com" && 
                      window.location.hostname !== "www.westsideeats.com"
console.log("global process.env.NODE_ENV", process.env.NODE_ENV)
console.log("global inProduction", inProduction)
console.log("global inDevelopment", inDevelopment)

let BASE_API_URL = inProduction
  ? "https://api.westsideeats.com"
  : "https://api.westsideeats.com"
const FILE_HOST_NAME = "https://d231biol73boai.cloudfront.net"
let address
let coordinates
let menu
let currentRestaurants = []
let userLocationInfo = null

function getLocalName()
{
  if (isCharlotte)
    return "Charlotte Eat"
  else
    return "West Side Eats"
}

function getLocalLogo(isSmallScreen)
{
  if (isCharlotte)
    return isSmallScreen ? "/CHAR_EATS.png" : "/CELogo.png"
  else
    return isSmallScreen ? "/WSELogo.png" : "/transparent-logo.png"
}

function getLocationCoordinates()
{
  if (isCharlotte)
    return "35.21145056445528,-80.85772524397129"
  else
    return "40.788460,-73.981060"
}

function getSEOName()
{
  if (isCharlotte)
    return "CharlotteEats"
  else
    return "WestSideEats"
}

function getSlogans()
{
  if (isCharlotte)
    return [
      "Built in Charlotte for the people of Charlotte",
      "Serving You and Supporting Local Restaurants"
    ]
  else
  return [
    "Built by Upper West Siders for Upper West Siders",
    "Serving You and Supporting Local Restaurants"
  ]
}

function getSampleAddress()
{
  if (isCharlotte)
    return "Enter Charlotte address (e.g. 1700 South Blvd)"
  else
    return "Enter a NY address (e.g. 101 West End Avenue)"
}

function getLocalBackground()
{
  if (isCharlotte)
    return "/charlottebackground.jpg"
  else 
    return "/opening.jpg"
}
// http://ec2-54-162-142-180.compute-1.amazonaws.com/cuisines
function makeServerAddress(path) {
  return BASE_API_URL + path
}

// http://uwse-images.s3-website-us-east-1.amazonaws.com/coffee.jpg
function makeFileServerAddress(path) {
  return FILE_HOST_NAME + path
}

export const fetcher = async (url, config) =>
  await axios({ baseURL: BASE_API_URL, url, ...config }).catch(console.error)

async function signOut(isRestaurant = false) {
  await appStorage.remove("token")
  if (isRestaurant) {
    await appStorage.remove("address")
  }
}

function getHeaderHeight() {
  if (Platform.OS == "web") {
    return 50
  }
  return 0
}

function updateCoordinates(userCoordinates) {
  coordinates = userCoordinates
}

function updateAddress(userAddress) {
  address = userAddress
}

function getAddress(isFormatted) {
  // if (address == null) {
  //   var addressjson = await appStorage.get("address");
  //   var addr = JSON.parse(addressjson);
  //   return isFormatted
  //     ? addr.address.structured_formatting.main_text
  //     : addr.address.description;
  // }
  return isFormatted
    ? address.structured_formatting.main_text
    : address.description
}

function getModalDimensions(isSmallScreen) {
  const basewidth = isSmallScreen
    ? Dimensions.get("window").width - 40
    : Dimensions.get("window").width * 0.2 > 300
    ? Dimensions.get("window").width * 0.2
    : 300
  return {
    width: basewidth,
    controlwidth: basewidth - 40,
    mincontrolwidth: 250,
    height: Dimensions.get("window").height - (isSmallScreen ? 40 : 200)
  }
}

async function updateUserAddress(locationInfo) {
  try {
    const token = await appStorage.get("token")
    const { address, coordinates, location } = locationInfo
    await fetch(makeServerAddress("/user/location"), {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token,
        address,
        location: coordinates,
        locationParts: location
      })
    })
  } catch (err) {
    console.error(err.message)
  }
}

async function updateLocation(address, details, user) {
  var streetNumber, route, city, state, zip
  for (const addrComp of details.address_components) {
    if (addrComp.types.includes("street_number"))
      streetNumber = addrComp.long_name
    else if (addrComp.types.includes("route")) route = addrComp.long_name
    else if (addrComp.types.includes("locality")) city = addrComp.long_name
    else if (addrComp.types.includes("administrative_area_level_1"))
      state = addrComp.short_name
    else if (addrComp.types.includes("postal_code")) zip = addrComp.long_name
  }

  const locationInfo = {
    address: address,
    coordinates: details.geometry.location,
    location: {
      address1: streetNumber + " " + route,
      city: city,
      state: state,
      zip: zip
    }
  }
  await setLocationInfo(locationInfo, user)
}

async function setLocationInfo(locationInfo, user) {
  updateAddress(locationInfo.address)
  updateCoordinates(locationInfo.coordinates)
  userLocationInfo = locationInfo
  await appStorage.store("address", JSON.stringify(locationInfo))
  if (user) {
    await updateUserAddress(locationInfo)
  }
}

function getLocationInfo() {
  return userLocationInfo
}

function setMenu(m) {
  menu = m
}

function getMenu() {
  return menu
}

function getCurrentRestaurants() {
  return currentRestaurants
}

function setCurrentRestaurants(restaurants) {
  currentRestaurants = restaurants
}

export const stripeKey = inProduction ? stripeProd : stripeTest

export const ctx = (styles, rules) => {
  const stylesUsed = Object.entries(rules).map(([name, rule]) => rule && name)

  return stylesUsed.map(name => styles[name])
}

export {
  signOut,
  makeServerAddress,
  makeFileServerAddress,
  getHeaderHeight,
  getAddress,
  getModalDimensions,
  updateLocation,
  setMenu,
  getMenu,
  getCurrentRestaurants,
  setCurrentRestaurants,
  setLocationInfo,
  getLocationInfo,
  updateUserAddress,
  getLocalName,
  getLocalLogo,
  getLocationCoordinates,
  getSEOName,
  getSlogans,
  getSampleAddress,
  getLocalBackground
}
