import React, { useState, useContext, useEffect, Fragment } from "react"
import { View, StyleSheet, FlatList, Dimensions } from "react-native"

import AuthContext from "../auth/context"
import colors from "../config/colors"
import appStorage from "../utility/cache"
import { makeServerAddress } from "../config/Globals"
import OrderCard from "../components/restaurant/OrderCard"
import Text from "../components/Text"
import SEO from "../components/SEO"

function OrdersScreen({ navigation }) {
  const { isSmallScreen } = useContext(AuthContext)
  const [orders, setOrders] = useState(null)
  const [noOrdersMessage, setNoOrdersMessage] = useState("")

  const getUserOrders = async () => {
    try {
      const authtoken = await appStorage.get("token")
      if (authtoken) {
        const response = await fetch(makeServerAddress(`/user/orders`), {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ token: authtoken })
        })
        const jsonData = await response.json()
        if (jsonData.length > 0) {
          setOrders(jsonData)
        } else {
          setNoOrdersMessage("You have no prior orders")
        }
      } else {
        setNoOrdersMessage("Login to see your orders")
      }
    } catch (err) {
      console.error(err.message)
    }
  }

  useEffect(() => {
    getUserOrders()
  }, [])

  return (
    <Fragment>
      <SEO title="My Orders" />
      {orders ? (
        <View style={styles.container}>
          <FlatList
            data={orders}
            style={{
              width: isSmallScreen ? Dimensions.get("window").width - 14 : "30%"
            }}
            keyExtractor={order => order._id}
            renderItem={({ item }) => (
              <OrderCard
                order={item.order}
                onPress={restaurantId =>
                  navigation.navigate("Restaurant", {
                    restaurantId: restaurantId
                  })
                }
              />
            )}
          />
        </View>
      ) : (
        <View style={styles.noOrdersContainer}>
          <Text>{noOrdersMessage}</Text>
        </View>
      )}
    </Fragment>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    backgroundColor: colors.light,
    marginTop: 50,
    flex: 1
  },
  noOrdersContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
  }
})

export default OrdersScreen
