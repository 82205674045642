import React, { useContext, useState } from "react"
import { StyleSheet } from "react-native"
import * as Yup from "yup"

import useModal from "../hooks/useModal"
import AuthContext from "../auth/context"
import { getModalDimensions, fetcher } from "../config/Globals"
import modals from "../config/modals"
import { AppFormField } from "../components/forms"
import FormLayout from "../layouts/FormLayout"

const AddRestaurantScreen = () => {
  const { isSmallScreen } = useContext(AuthContext)
  const { toggleModal } = useModal()
  const { controlwidth } = getModalDimensions(isSmallScreen)
  const [error, setError] = useState(false)
  const validationSchema = Yup.object().shape(
    {
      restaurantName: Yup.string().required().label("Restaurant Name"),
      phoneNumber: Yup.string()
        .label("Phone Number")
        .when("email", {
          is: value => !value?.length,
          then: Yup.string().required(
            "You must provide an email or phone number"
          )
        }),
      email: Yup.string()
        .email("Please enter a valid email")
        .label("Email")
        .when("phoneNumber", {
          is: value => !value?.length,
          then: Yup.string().required(
            "You must provide an email or phone number"
          )
        }),
      comments: Yup.string().label("Comments")
    },
    [["email", "phoneNumber"]]
  )

  const handleSubmit = async values => {
    const response = await fetcher("/restaurant/add-your-restaurant", {
      method: "post",
      data: values
    })

    if (response.statusText === "OK") return toggleModal(modals.ADD_RESTAURANT)

    setError(true)
  }

  const styles = StyleSheet.create({
    formField: {
      height: isSmallScreen ? 30 : 40,
      width: controlwidth,
      marginBottom: isSmallScreen ? 40 : 30
    },
    multilineInput: {
      height: 50
    }
  })

  return (
    <FormLayout
      name={modals.ADD_RESTAURANT}
      error={error}
      initialValues={{
        restaurantName: "",
        phoneNumber: "",
        email: "",
        comments: ""
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <AppFormField
        name="restaurantName"
        placeholder="Restaurant Name"
        style={styles.formField}
        autoCapitalize="none"
        autoCorrect={false}
      />
      <AppFormField
        name="phoneNumber"
        placeholder="Phone Number"
        style={styles.formField}
        autoCapitalize="none"
        autoCorrect={false}
      />
      <AppFormField
        name="email"
        placeholder="Email"
        style={styles.formField}
        textContentType="emailAddress"
        autoCapitalize="none"
        autoCorrect={false}
      />
      <AppFormField
        name="comments"
        placeholder="Comments"
        multiline
        numberOfLines={2}
        style={[styles.formField, styles.multilineInput]}
        autoCapitalize="none"
        autoCorrect={false}
      />
    </FormLayout>
  )
}

export default AddRestaurantScreen
