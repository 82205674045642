import { AsyncStorage } from "react-native"
import dayjs from "dayjs"

const prefix = "cachev2"
const expiryInMinutes = 60 * 24 * 30 // 30 days

const store = async (key, value) => {
  try {
    const item = {
      value,
      timestamp: Date.now()
    }
    await AsyncStorage.setItem(prefix + key, JSON.stringify(item))
  } catch (error) {
    console.log(error)
  }
}

const isExpired = item => {
  return false
  const now = dayjs()
  const storedTime = dayjs(item.timestamp)
  return now.diff(storedTime, "minute") > expiryInMinutes
}

const remove = async key => {
  await AsyncStorage.removeItem(prefix + key)
}

const get = async key => {
  try {
    const value = await AsyncStorage.getItem(prefix + key)
    const item = JSON.parse(value)

    if (!item) return null

    if (isExpired(item)) {
      // Command Query Separation (CQS)
      await remove(key)
      return null
    }

    return item.value
  } catch (error) {
    console.log(error)
  }
}

export default {
  store,
  get,
  remove
}
