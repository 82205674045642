import React, { createContext, useState, useMemo } from "react"

export const ModalContext = createContext()

export const ModalProvider = ({ children }) => {
  const [current, setCurrent] = useState(null)

  const isOpen = modal => current === modal

  const toggleModal = modal => {
    setCurrent(isOpen(modal) ? null : modal)
  }

  const memoizedContextValue = useMemo(
    () => ({
      isOpen,
      toggleModal
    }),
    [current]
  )

  return (
    <ModalContext.Provider value={memoizedContextValue}>
      {children}
    </ModalContext.Provider>
  )
}
