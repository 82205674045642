import React, { Fragment, useState, useContext, useEffect } from "react"
import {
  Dimensions,
  Image,
  StyleSheet,
  TouchableWithoutFeedback,
  TouchableOpacity,
  View
} from "react-native"
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers
} from "react-native-popup-menu"
import { MaterialCommunityIcons } from "@expo/vector-icons"
import Autocomplete from "react-native-autocomplete-input"
import { LinearGradient } from "expo-linear-gradient"

import AuthContext from "../auth/context"
import colors from "../config/colors"
import {
  getHeaderHeight,
  getModalDimensions,
  getCurrentRestaurants,
  signOut,
  makeFileServerAddress,
  makeServerAddress,
  getLocalLogo
} from "../config/Globals"
import { getCart } from "../config/Cart"
import AddressTag from "./AddressTag"
import Button from "../components/Button"
import CartList from "./cart/CartList"
import CloseButton from "./CloseButton"
import Hoverable from "./Hoverable"
import LoginScreen from "../screens/LoginScreen"
import Modal from "modal-enhanced-react-native-web"
import Text from "./Text"

const { Popover } = renderers

function WebHeader({ navigation, simpleHeader }) {
  const { user, setUser, isSmallScreen, coordinates } = useContext(AuthContext)
  const { width, controlwidth, mincontrolwidth, height } =
    getModalDimensions(isSmallScreen)
  const logoWidth = isSmallScreen ? 30 : 40
  const [modalVisible, setModalVisible] = useState(false)
  const [loginModalVisible, setLoginModalVisible] = useState(false)
  const [checkingOut, setCheckingOut] = useState(false)
  const [subtotal, setSubtotal] = useState(0.0)
  const [filteredRestaurants, setFilteredRestaurants] = useState([])

  const findRestaurant = query => {
    if (query) {
      const regex = new RegExp(`${query.trim()}`, "i")
      setFilteredRestaurants(
        getCurrentRestaurants().filter(
          restaurant => restaurant.name.search(regex) >= 0
        )
      )
    } else setFilteredRestaurants([])
  }

  const checkDelivery = async () => {
    try {
      const response = await fetch(
        makeServerAddress(
          `/restaurant/canDeliver?restaurantId=${getCart().restaurantId}&lng=${
            coordinates.lng
          }&lat=${coordinates.lat}`
        )
      )

      if (response.status === 200) {
        const jsonData = await response.json()
        return jsonData.canDeliver
      }
      return true
    } catch (err) {
      console.error(err)
    }
  }

  const styles = StyleSheet.create({
    cartContainer: {
      alignItems: "center",
      flexDirection: "row",
      alignSelf: "center",
      width: 35,
      height: 40,
      marginLeft: isSmallScreen ? 5 : 20,
      marginRight: isSmallScreen ? 5 : 20,
      borderRadius: 10
    },
    backButton: {
      fontWeight: "bold",
      color: colors.black,
      marginLeft: 10
    },
    backButtonContainer: {
      width: 120,
      flexDirection: "row",
      alignItems: "center"
    },
    header: {
      position: "fixed",
      width: "100%",
      zIndex: 2
    },
    headerBody: {
      backgroundColor: colors.white,
      width: "100%",
      height: getHeaderHeight(),
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      paddingHorizontal: 10
    },
    headerBorder: {
      width: "100%",
      height: 2
    },
    logo: {
      width: logoWidth,
      height: logoWidth,
      borderRadius: "50%"
    },
    menuContainer: {
      width: !isSmallScreen && 400,
      flexDirection: "row-reverse",
      alignItems: "center"
    },
    menuOption: {
      color: "black",
      fontWeight: "bold",
      fontSize: 14,
      paddingTop: 5
    },
    modal: {
      width: width,
      height: height,
      minHeight: 600,
      minWidth: isSmallScreen ? null : 400,
      alignSelf: isSmallScreen ? "center" : "flex-end",
      justifyContent: "space-between",
      backgroundColor: colors.white,
      alignItems: "center",
      paddingBottom: 20,
      borderRadius: 20
    }
  })

  return (
    <Fragment>
      <View style={styles.header}>
        <View style={styles.headerBody}>
          {!simpleHeader && !isSmallScreen && (
            <View style={{ width: 400 }}>
              <AddressTag navigation={navigation} />
            </View>
          )}
          {simpleHeader && (
            <TouchableWithoutFeedback onPress={() => navigation.goBack()}>
              <View style={styles.backButtonContainer}>
                <MaterialCommunityIcons
                  name="arrow-left"
                  size={30}
                  color={colors.black}
                />
                <Text style={styles.backButton}>Go Back</Text>
              </View>
            </TouchableWithoutFeedback>
          )}
          <Hoverable>
            {isHovered => (
              <TouchableWithoutFeedback onPress={() => navigation.push("Home")}>
                <Image
                  source={{
                    uri: makeFileServerAddress(getLocalLogo(false))
                  }}
                  style={[
                    styles.logo,
                    { backgroundColor: isHovered && colors.light }
                  ]}
                />
              </TouchableWithoutFeedback>
            )}
          </Hoverable>
          {!simpleHeader && (
            <View style={styles.menuContainer}>
              {user && (
                <Menu
                  renderer={Popover}
                  rendererProps={{ placement: "bottom" }}
                  onSelect={async value => {
                    if (value == 3) {
                      setUser(null)
                      await signOut()
                      navigation.push("Opening")
                    } else if (value == 2) {
                      navigation.push("Orders")
                    }
                  }}
                >
                  <MenuTrigger>
                    <MaterialCommunityIcons
                      name="account-circle"
                      size={40}
                      color={colors.medium}
                    />
                  </MenuTrigger>
                  <MenuOptions
                    style={{ backgroundColor: colors.light, width: 150 }}
                  >
                    <View
                      style={{ padding: 2, backgroundColor: "lightyellow" }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          fontWeight: "bold",
                          color: colors.black
                        }}
                      >
                        {" "}
                        Welcome, {user.name}
                      </Text>
                    </View>
                    <MenuOption value={2}>
                      <Text style={styles.menuOption}>Your Orders</Text>
                    </MenuOption>
                    <MenuOption value={3}>
                      <Text style={styles.menuOption}>Sign Out</Text>
                    </MenuOption>
                  </MenuOptions>
                </Menu>
              )}
              {!user && (
                <Button
                  title="Login"
                  styleButton={{ width: isSmallScreen ? 60 : 90, height: 30 }}
                  styleText={{ color: colors.white }}
                  onPress={() => setLoginModalVisible(true)}
                  isSmallScreen={isSmallScreen}
                />
              )}
              <Hoverable>
                {isHovered => (
                  <TouchableWithoutFeedback
                    onPress={() => {
                      setModalVisible(true)
                      setSubtotal(getCart().subtotal)
                    }}
                  >
                    <View
                      style={[
                        styles.cartContainer,
                        isHovered && {
                          backgroundColor: colors.light
                        }
                      ]}
                    >
                      <MaterialCommunityIcons
                        name="cart"
                        size={25}
                        color="black"
                      />
                    </View>
                  </TouchableWithoutFeedback>
                )}
              </Hoverable>
              <View
                style={{
                  width: isSmallScreen ? 140 : 170,
                  alignSelf: "center"
                }}
              >
                <Autocomplete
                  autoCapitalize="none"
                  autoCorrect={false}
                  containerStyle={{
                    backgroundColor: "#ffffff",
                    borderWidth: 1,
                    height: 32,
                    borderColor: "lightgrey"
                  }}
                  inputContainerStyle={{
                    borderWidth: 0,
                    height: 30
                  }}
                  data={filteredRestaurants}
                  defaultValue=""
                  onChangeText={text => findRestaurant(text)}
                  placeholder="Search"
                  renderItem={({ item }) => (
                    <TouchableOpacity
                      onPress={() => {
                        setFilteredRestaurants([])
                        navigation.push("Restaurant", {
                          restaurantId: item._id,
                          restaurantName: item.name,
                          menuId: item.menuId,
                          relayProducerKey: item.relayProducerKey
                        })
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 15,
                          paddingTop: 5,
                          paddingBottom: 2,
                          margin: 2
                        }}
                      >
                        {item.name}
                      </Text>
                      <Text
                        style={{
                          fontSize: 12,
                          paddingBottom: 5,
                          margin: 2
                        }}
                      >
                        {item.address.substring(
                          item.address.lastIndexOf(",") + 1
                        )}
                      </Text>
                      <View
                        style={{
                          borderBottomColor: "black",
                          borderBottomWidth: 1,
                          width: 200
                        }}
                      />
                    </TouchableOpacity>
                  )}
                />
              </View>
            </View>
          )}
          {simpleHeader && !isSmallScreen && <View style={{ width: 120 }} />}
        </View>
        <LinearGradient
          colors={colors.wseGradient}
          style={styles.headerBorder}
          start={{ x: 0.1, y: 0.5 }}
          end={{ x: 1, y: 0.5 }}
        />
      </View>
      {isSmallScreen && !simpleHeader && (
        <View
          style={{
            width: "100%",
            height: 50,
            alignItems: "center",
            top: getHeaderHeight() + 10
          }}
        >
          <AddressTag navigation={navigation} />
        </View>
      )}
      {!simpleHeader && (
        <Modal
          isVisible={modalVisible}
          onBackdropPress={() => setModalVisible(false)}
          animationIn={isSmallScreen ? "zoomIn" : "slideInRight"}
          animationOut={isSmallScreen ? "zoomOut" : "slideOutRight"}
        >
          <View style={styles.modal}>
            <CloseButton
              style={{
                alignSelf: "flex-end",
                padding: 10,
                paddingBottom: 5
              }}
              onPress={() => setModalVisible(false)}
            />
            <View
              style={{
                backgroundColor: "lightgrey",
                width: controlwidth,
                minWidth: mincontrolwidth,
                borderRadius: 10,
                padding: 10,
                alignItems: "center"
              }}
            >
              <Text style={{ fontWeight: "bold", fontSize: 20 }}>My Order</Text>
            </View>
            <CartList subTotal={getCart().subtotal} setSubtotal={setSubtotal} />
            <View style={{ width: controlwidth, minWidth: mincontrolwidth }}>
              <Button
                title="Checkout"
                color="medium"
                disabled={subtotal === 0}
                onPress={async () => {
                  setModalVisible(false)
                  if (user) {
                    const canDeliver = await checkDelivery()
                    navigation.push("Cart", { canDeliver })
                  } else {
                    setLoginModalVisible(true)
                    setCheckingOut(true)
                  }
                }}
              />
            </View>
          </View>
        </Modal>
      )}
      <LoginScreen
        navigation={navigation}
        loginModalVisible={loginModalVisible}
        setLoginModalVisible={setLoginModalVisible}
        checkingOut={checkingOut}
        atHomeScreen={true}
      />
    </Fragment>
  )
}

export default WebHeader
