import React, { Component } from "react"
import {
  StyleSheet,
  Image,
  View,
  TouchableWithoutFeedback,
  Platform,
  Dimensions
} from "react-native"

import Text from "../Text"
import colors from "../../config/colors"
import { makeFileServerAddress } from "../../config/Globals"
import Hoverable from "../Hoverable"
import AuthContext from "../../auth/context"

function RestaurantCard({ name, cuisine, image1, image2, isOpen, onPress }) {
  const { isSmallScreen } = React.useContext(AuthContext)
  const styles = StyleSheet.create({
    touchable: {
      marginBottom: 0,
      backgroundColor: colors.white
    },
    image: {
      width: isSmallScreen ? (Dimensions.get("window").width - 34) / 2 : 305,
      height: isSmallScreen ? (Dimensions.get("window").width - 34) / 4 : 152.5,
      margin: 5,
      borderRadius: 5
    },
    restaurantCard: {
      backgroundColor: colors.white,
      borderRadius: 3,
      shadowOffset: { width: 1, height: 1 },
      shadowOpacity: 0.5,
      shadowColor: colors.medium,
      shadowRadius: 2,
      marginVertical: 10,
      overflow: "hidden",
      width: isSmallScreen ? Dimensions.get("window").width - 14 : "90%",
      alignSelf: "center"
    },
    cuisine: {
      color: colors.medium,
      fontSize: 13,
      fontFamily: Platform.OS === "android" ? "Roboto" : "Arial"
    }
  })

  return (
    <Hoverable>
      {isHovered => (
        <TouchableWithoutFeedback
          delayLongPress={isSmallScreen ? 100 : 50}
          onLongPress={onPress}
        >
          <View
            style={[
              styles.restaurantCard,
              isHovered && {
                backgroundColor: colors.light,
                shadowOffset: { width: 3, height: 3 }
              }
            ]}
          >
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Image
                style={styles.image}
                source={{ uri: makeFileServerAddress(image1, "8080") }}
                blurRadius={isOpen ? 0 : 3}
              />
              <Image
                style={styles.image}
                source={{ uri: makeFileServerAddress(image2, "8080") }}
                blurRadius={isOpen ? 0 : 3}
              />
            </View>
            <View style={{ padding: 10 }}>
              <Text
                style={{
                  fontWeight: "bold",
                  fontFamily: Platform.OS === "android" ? "Roboto" : "Arial",
                  color: colors.wseblue
                }}
                numberOfLines={1}
              >
                {name}
              </Text>
              <Text style={styles.cuisine} numberOfLines={2}>
                {cuisine}
              </Text>
            </View>
          </View>
        </TouchableWithoutFeedback>
      )}
    </Hoverable>
  )
}

export default RestaurantCard
