import React from "react"
import { View, TextInput, Platform } from "react-native"

import colors from "../../config/colors"

function TipInput({ tip, setTip }) {
  return (
    <View>
      <TextInput
        style={{
          height: 25,
          width: 75,
          borderColor: colors.black,
          borderWidth: 2,
          borderRadius: 5,
          fontSize: 18,
          fontFamily: Platform.OS === "android" ? "Roboto" : "Arial",
          color: colors.dark
        }}
        keyboardType="numbers-and-punctuation"
        placeholder={tip}
        onChangeText={text => {
          setTip(parseFloat(text.length === 0 ? "0" : text))
        }}
      />
    </View>
  )
}

export default TipInput
