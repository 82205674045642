import React from "react"
import { Helmet } from "react-helmet"
import { getSEOName } from "../../config/Globals"

const SEO = ({
  title,
  description,
  lang = "en",
  meta = [],
  keywords = [],
  subCategory
}) => {
  const site = {
    siteMetaData: {
      title: getSEOName(),
      description:
        "A community-based app that supports local restaurants and other charities in the Upper West Side",
      author: "The WSE Team",
      baseKeywords: [
        "West Side Eats",
        "Local Restaurants",
        "WestSideEats",
        "WestSide Eats",
        "Upper West Side",
        "West Side Restaurants"
      ]
    }
  }

  const metaDescription = description ?? site.siteMetaData.description
  const metaKeywords = [...site.siteMetaData.baseKeywords, ...keywords]

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={subCategory ? `${title} - ${subCategory}` : title}
      titleTemplate={`%s | ${site.siteMetaData.title}`}
      meta={[
        {
          name: "description",
          content: metaDescription
        },
        {
          property: "og:title",
          content: title
        },
        {
          property: "og:description",
          content: metaDescription
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          name: "twitter:card",
          content: "summary"
        },
        {
          name: "twitter:creator",
          content: site.siteMetaData.author
        },
        {
          name: "twitter:title",
          content: title
        },
        {
          name: "twitter:description",
          content: metaDescription
        },
        {
          name: "keywords",
          content: metaKeywords.join(", ")
        },
        ...meta
      ]}
    />
  )
}

export default SEO
