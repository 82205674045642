import React from "react"
import { useNavigation } from "@react-navigation/native"
import * as SC from "./styles"

import Hoverable from "../Hoverable"

const FooterLink = ({ title, to, onPress }) => {
  const navigation = useNavigation()

  const handlePress = () => {
    onPress?.()
    if (to) navigation.navigate(to)
  }

  return (
    <Hoverable>
      {isHovered => (
        <SC.FooterText
          onPress={handlePress}
          style={{ textDecorationLine: isHovered && "underline" }}
        >
          {title}
        </SC.FooterText>
      )}
    </Hoverable>
  )
}

export default FooterLink
