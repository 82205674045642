import React from "react"
import { View } from "react-native"
import Modal from "modal-enhanced-react-native-web"

import colors from "../../config/colors"
import Button from "../Button"
import Text from "../Text"
import { replaceCart } from "../../config/Cart"

function ReplaceOrderModal({
  modalVisible,
  setModalVisible,
  setMenuItemModalVisible,
  menuItem
}) {
  return (
    <Modal isVisible={modalVisible}>
      <View style={{ alignSelf: "center" }}>
        <View
          style={{
            backgroundColor: colors.white,
            justifyContent: "flex-end",
            alignItems: "flex-end",
            width: 300,
            height: 200,
            borderRadius: 5
          }}
        >
          <View style={{ marginLeft: 10 }}>
            <Text>
              Your order can only contain items from one restaurant. Would you
              like to replace your current cart with this item?
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Button
              title="Replace"
              styleButton={{ height: 50 }}
              onPress={async () => {
                setModalVisible(false)
                setMenuItemModalVisible(false)
                await replaceCart(menuItem)
              }}
            />
            <Button
              title="Cancel"
              styleButton={{ height: 50, marginHorizontal: 10 }}
              onPress={() => {
                setModalVisible(false)
              }}
            />
          </View>
        </View>
      </View>
    </Modal>
  )
}

export default ReplaceOrderModal
