import React, { useState, useEffect } from "react"
import { View, StyleSheet } from "react-native"
import { makeServerAddress } from "../config/Globals"

import Button from "./Button"

function OrderStatusButton({ orderId, orders, setOrders, buttonWidth }) {
  const [status, setStatus] = useState("")

  const getOrderStatus = async () => {
    try {
      const response = await fetch(
        makeServerAddress(`/order/status/${orderId}`)
      )
      const orderStatus = await response.json()
      setStatus(orderStatus)
    } catch (err) {
      console.error(err.message)
    }
  }

  const updateOrderStatus = async newStatus => {
    try {
      await fetch(makeServerAddress(`/order/status/${orderId}`), {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          status: newStatus
        })
      })
    } catch (err) {
      console.error(err.message)
    }

    const newOrders = orders.map(order => ({
      ...order,
      status: order._id === orderId ? newStatus : order.status
    }))
    setOrders(newOrders)
  }

  const handleOnPress = status => () => {
    setStatus(status)
    updateOrderStatus(status)
  }

  useEffect(() => {
    getOrderStatus()
  }, [orderId])

  const styles = StyleSheet.create({
    button: {
      marginHorizontal: 7,
      padding: 7,
      borderRadius: 5,
      width: buttonWidth
    },
    container: {
      flexDirection: "row"
    }
  })

  return (
    <View style={styles.container}>
      <Button
        title="New"
        color={status === "New" ? "blue" : "lightgrey"}
        onPress={handleOnPress("New")}
        styleButton={styles.button}
      />
      <Button
        title="In Progress"
        color={status === "In Progress" ? "yellow" : "lightgrey"}
        onPress={handleOnPress("In Progress")}
        styleButton={styles.button}
      />
      <Button
        title="Ready"
        color={status === "Ready" ? "green" : "lightgrey"}
        onPress={handleOnPress("Ready")}
        styleButton={styles.button}
      />
    </View>
  )
}

export default OrderStatusButton
