import React from "react"
import { View, StyleSheet, TouchableOpacity } from "react-native"
import { Entypo } from "@expo/vector-icons"

const DeleteCartItemButton = props => {
  return (
    <View>
      <TouchableOpacity {...props} style={{ ...props.style, ...styles.button }}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Entypo name="circle-with-cross" size={22} color="red" />
        </View>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  button: {
    justifyContent: "center",
    alignItems: "center"
  }
})

export default DeleteCartItemButton
