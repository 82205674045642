import React from "react"
import { StyleSheet } from "react-native"

import useModal from "../hooks/useModal"
import colors from "../config/colors"
import { AppForm } from "../components/forms"
import CloseButton from "../components/CloseButton"
import ModalLayout from "./ModalLayout"
import { SubmitButton } from "../components/forms"
import Text from "../components/Text"

const FormLayout = ({ name, error, children, ...props }) => {
  const { toggleModal } = useModal()

  return (
    <ModalLayout name={name}>
      <Text style={styles.title}>{name}</Text>
      <AppForm {...props}>
        {children}
        <SubmitButton title="Submit" styleButton={styles.submitButton} />
      </AppForm>
      <CloseButton
        style={styles.closeButton}
        onPress={() => toggleModal(name)}
      />
      {error && (
        <Text style={styles.errorText}>
          There was an error processing your request. Please try again.
        </Text>
      )}
    </ModalLayout>
  )
}

const styles = StyleSheet.create({
  closeButton: {
    position: "absolute",
    top: 0,
    right: 0
  },
  errorText: {
    textAlign: "center",
    fontSize: 12,
    color: colors.error
  },
  submitButton: {
    width: "50%"
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    paddingTop: 16
  }
})

export default FormLayout
