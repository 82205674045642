import React from "react"
import * as SC from "./styles"
import { MaterialCommunityIcons } from "@expo/vector-icons"

import colors from "../../config/colors"

const CloseButton = ({ size = 25, onPress, ...props }) => {
  return (
    <SC.ButtonWrapper onPress={onPress} {...props}>
      <MaterialCommunityIcons name="close" size={size} color={colors.black} />
    </SC.ButtonWrapper>
  )
}

export default CloseButton
