import React, { Fragment } from "react"
import { View, Text, ImageBackground } from "react-native"

import appStorage from "../utility/cache"
import { updateIsDelivery } from "../config/Cart"
import { makeFileServerAddress, getLocalBackground } from "../config/Globals"
import Button from "../components/Button"
import SEO from "../components/SEO"

const DeliveryOrPickupScreen = ({ navigation }) => {
  return (
    <Fragment>
      <SEO title="Delivery or Pickup" />
      <View style={{ alignItems: "center", flex: 1, justifyContent: "center" }}>
        <ImageBackground
          source={{ uri: makeFileServerAddress(getLocalBackground(), "8080") }}
          style={{ flex: 1, width: "100%", justifyContent: "center" }}
        >
          <View style={{ alignSelf: "center" }}>
            <Text style={{ fontSize: 30, color: "white", fontWeight: "bold" }}>
              Select Order Type
            </Text>
          </View>
          <View style={{ alignItems: "center", marginTop: 50 }}>
            <Button
              title="Delivery"
              onPress={async () => {
                updateIsDelivery(true)
                await appStorage.store("ordertype", "true")
                navigation.push("Home")
              }}
              styleButton={{
                width: 200
              }}
            />
            <Text style={{ fontSize: 14, color: "white", fontWeight: "bold" }}>
              OR
            </Text>
            <Button
              title="Pickup"
              onPress={async () => {
                updateIsDelivery(false)
                await appStorage.store("ordertype", "false")
                navigation.push("Home")
              }}
              styleButton={{
                width: 200
              }}
            />
          </View>
        </ImageBackground>
      </View>
    </Fragment>
  )
}

export default DeliveryOrPickupScreen
