import React, { useContext } from "react"
import { View, StyleSheet, Image, Text, Dimensions } from "react-native"

import AuthContext from "../../auth/context"
import { makeFileServerAddress } from "../../config/Globals"

function Restaurant({ info }) {
  const { isSmallScreen } = useContext(AuthContext)

  const styles = StyleSheet.create({
    address: {
      fontSize: 17
    },
    container: {
      alignItems: "flex-start"
    },
    hours: {
      fontSize: 15
    },
    image: {
      width: "100%",
      height: isSmallScreen ? Dimensions.get("window").width * 0.4 : 225,
      resizeMode: isSmallScreen ? null : "cover"
    },
    logo: {
      width: 60,
      height: 60
    },
    infoContainer: {
      bottom: 20
    },
    textContainer: {
      left: 30,
      bottom: 10
    },
    title: {
      fontSize: 25,
      fontWeight: "bold"
    }
  })

  return (
    <View style={styles.container}>
      <Image
        style={styles.image}
        source={{ uri: makeFileServerAddress(info.image3, "8080") }}
      />
      <View style={styles.infoContainer}>
        <Image
          style={styles.logo}
          source={{ uri: makeFileServerAddress(info.logo, "8080") }}
        />
        <View style={styles.textContainer}>
          <Text style={styles.title}>{info.name}</Text>
          <Text style={styles.address}>{info.address}</Text>
          <Text style={styles.hours}>{info.time}</Text>
        </View>
      </View>
    </View>
  )
}

export default Restaurant
