import React from "react"
import { Platform, StyleSheet } from "react-native"

import Text from "../Text"

function ErrorMessage({ style, error, visible }) {
  if (!visible || !error) return null

  return <Text style={[styles.error, style]}>{error}</Text>
}

const styles = StyleSheet.create({
  error: {
    color: "red",
    fontSize: 10,
    fontFamily: Platform.OS === "android" ? "Roboto" : "Arial"
  }
})

export default ErrorMessage
