export default {
  isCheckingOut: false,
  needUpdate: false,
  email: "mikecox579@gmail.com",
  password: "Mikecox579@",
  creditCard: [
    {
      id: 1,
      number: "4358 8074 2519 0483",
      expiration: "",
      name: "",
      cvv: 0,
      zip: 0,
      hiddenNum: "**0483"
    },

    {
      id: 2,
      number: "1234 5678 9101 1123",
      expiration: "",
      name: "",
      cvv: 0,
      zip: 0,
      hiddenNum: "**1123"
    }
  ],

  deliveryInfo: [
    {
      id: 1,
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      instructions: ""
    }
  ]
}
