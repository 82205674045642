import React from "react"
import { Dimensions } from "react-native"
import { NavigationContainer } from "@react-navigation/native"
import { Provider as PaperProvider } from "react-native-paper"
import { MenuProvider } from "react-native-popup-menu"
import JwtDecode from "jwt-decode"

import OpeningScreenNavigator from "./app/navigation/OpeningScreenNavigator"
import { ModalProvider } from "./app/ModalProvider"
import AuthContext from "./app/auth/context"
import appStorage from "./app/utility/cache"
import { makeServerAddress, setLocationInfo } from "./app/config/Globals"
import { setCart, updateIsDelivery } from "./app/config/Cart"

function App() {
  const [isReady, setIsReady] = React.useState(false)
  const [user, setUser] = React.useState(null)
  const [numItems, setNumItems] = React.useState(0)
  const [selectedCuisine, setSelectedCuisine] = React.useState("")
  const [isSmallScreen, setIsSmallScreen] = React.useState(
    Dimensions.get("window").width <= 500 ? true : false
  )
  const [orderSelected, setOrderSelected] = React.useState("")
  const [coordinates, setCoordinates] = React.useState({})
  const [hasAddress, setHasAddress] = React.useState(false)
  const [isDelivery, setIsDelivery] = React.useState(true)

  const onChange = () => {
    var newSmallScreen = Dimensions.get("window").width <= 500 ? true : false
    if (newSmallScreen != isSmallScreen) setIsSmallScreen(newSmallScreen)
  }

  console.log("Url", window.location.href)
  React.useEffect(async () => {
    try {
      const authtoken = await appStorage.get("token")
      var newUser = null
      if (authtoken) {
        // Validate token
        const response = await fetch(makeServerAddress("/validate"), {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            token: authtoken
          })
        })

        if (response.status == 200) {
          newUser = JwtDecode(authtoken)
          setUser(newUser)
        } else {
          await appStorage.remove("token")
          setUser(null)
        }
      } else setUser(null)

      const cart = await appStorage.get("cart")
      if (cart) {
        setCart(cart)
        setNumItems(cart.numItems)
      }

      var coord = null
      const cacheAddress = await appStorage.get("address")

      if (cacheAddress) {
        var addr = JSON.parse(cacheAddress)
        setHasAddress(true)
        await setLocationInfo(addr, null)
      } else if (newUser) {
        const response = await fetch(makeServerAddress(`/user/${newUser._id}`))
        const jsonData = await response.json()
        if ("address" in jsonData) {
          coord = jsonData.location
          await setLocationInfo(
            {
              address: jsonData.address,
              coordinates: jsonData.location,
              location: jsonData.locationParts
            },
            null
          )
        }
      }

      if (!coord) {
        if (addr) {
          coord = addr.coordinates
        }
      }

      setCoordinates(coord)

      // get the order option
      const ordertype = await appStorage.get("ordertype")
      if (ordertype) {
        updateIsDelivery(ordertype === "true" ? true : false)
        setIsDelivery(ordertype === "true" ? true : false)
      }

      setIsReady(true)
      Dimensions.addEventListener("change", onChange)
      return () => Dimensions.removeEventListener("change", onChange)
    } catch (err) {
      console.error(err.message)
    }
  }, [])

  if (!isReady) {
    return null
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        numItems,
        setNumItems,
        selectedCuisine,
        setSelectedCuisine,
        isSmallScreen,
        setIsSmallScreen,
        orderSelected,
        setOrderSelected,
        coordinates,
        setCoordinates,
        hasAddress,
        setHasAddress,
        isDelivery,
        setIsDelivery
      }}
    >
      <ModalProvider>
        <PaperProvider>
          <MenuProvider>
            <NavigationContainer linking={{ enabled: true }}>
              <OpeningScreenNavigator />
            </NavigationContainer>
          </MenuProvider>
        </PaperProvider>
      </ModalProvider>
    </AuthContext.Provider>
  )
}

export default App
