import React, { useState, useRef, useEffect, useContext } from "react"
import { Animated, View } from "react-native"
import { computeStyles } from "./styles"

import AuthContext from "../../auth/context"
import { ctx, getSlogans } from "../../config/Globals"
import AnimatedText from "../AnimatedText"

const slogans = getSlogans()

const Slogan = ({ type }) => {
  const fadeAnim = useRef(new Animated.Value(0)).current

  const { isSmallScreen } = useContext(AuthContext)
  const [index, setIndex] = useState(0)
  const styles = computeStyles(isSmallScreen)

  useEffect(() => {
    Animated.sequence([
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 2000
      }),
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 2000
      })
    ]).start()
  }, [fadeAnim, index])

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex(index => (index < slogans.length - 1 ? index + 1 : 0))
    }, 4000)

    return () => clearInterval(interval)
  })

  return (
    <View
      style={ctx(styles, {
        openingContainer: type === "opening",
        homeContainer: type === "home"
      })}
    >
      <AnimatedText animation={fadeAnim} style={styles[type]}>
        {slogans[index]}
      </AnimatedText>
    </View>
  )
}

export default Slogan
