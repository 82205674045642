import React from "react"
import { StyleSheet, FlatList, Image, View, Platform } from "react-native"
import Toast from "react-native-tiny-toast"
import "react-native-get-random-values"

import Screen from "../components/Screen"
import ItemSeparator from "../components/restaurant/ItemSeparator"
import List from "../components/List"
import Text from "../components/Text"
import Button from "../components/Button"
import colors from "../config/colors"
import { makeServerAddress } from "../config/Globals"
import Modal from "modal-enhanced-react-native-web"

const itemOptions = [
  {
    name: "Pepperoni Pizza",
    image: require("../assets/pizza.jpg"),
    options: [
      {
        header: "Size",
        key: 1,
        list: [
          {
            value: 1,
            label: '12" Small',
            price: 16.99,
            default: true
          },
          {
            value: 2,
            label: '14" Medium',
            price: 19.99
          },
          {
            value: 3,
            label: '19" Large',
            price: 25.99
          }
        ]
      },
      {
        header: "Crust",
        key: 2,
        list: [
          {
            value: 1,
            label: "Gluten-Free",
            price: 4.0,
            default: false
          },
          {
            value: 2,
            label: "Multi-Grain",
            price: 0,
            default: false
          },
          {
            value: 3,
            label: "NY Hand-Tossed",
            price: 0,
            default: true
          }
        ]
      },
      {
        header: "Toppings",
        condition: true,
        key: 3,
        list: [
          {
            value: 1,
            label: "Red Onions",
            price: 0.89,
            default: false
          },
          {
            value: 2,
            label: "Tomato",
            price: 0.89,
            default: false
          },
          {
            value: 3,
            label: "Italian Sausage",
            price: 0.89,
            default: false
          },
          {
            value: 4,
            label: "Green Peppers",
            price: 0.89,
            default: false
          }
        ]
      }
    ]
  },
  {
    name: "Bacon & Cheese Waffle Fries",
    image: require("../assets/fries.jpg"),
    options: [
      {
        header: "Jalapeños?",
        key: 1,
        list: [
          {
            value: 1,
            label: "Yes",
            price: 0.99,
            default: false
          },
          {
            value: 2,
            label: "No",
            price: 0,
            default: true
          }
        ]
      }
    ]
  },
  {
    name: "Crispy Buffalo Wings",
    image: require("../assets/wings.jpg"),
    options: [
      {
        header: "Size",
        key: 1,
        list: [
          {
            value: 1,
            label: "7 Piece",
            price: 9.95,
            default: true
          },
          {
            value: 2,
            label: "14 Piece",
            price: 19.5
          },
          {
            value: 3,
            label: "21 Piece",
            price: 28.95
          },
          {
            value: 4,
            label: "30 Piece",
            price: 37.95
          }
        ]
      },
      {
        header: "Dressing",
        key: 2,
        list: [
          {
            value: 1,
            label: "Blue Cheese",
            price: 0,
            default: false
          },
          {
            value: 2,
            label: "Ranch",
            price: 0,
            default: true
          }
        ]
      },
      {
        header: "Flavor",
        key: 3,
        condition: true,
        list: [
          {
            value: 1,
            label: "Asian Chili",
            price: 0
          },
          {
            value: 2,
            label: "BBQ",
            price: 0
          },
          {
            value: 3,
            label: "El Diablo",
            price: 0
          },
          {
            value: 4,
            label: "Hot",
            price: 0
          },
          {
            value: 5,
            label: "Hot Honey BBQ",
            price: 0
          },
          {
            value: 6,
            label: "Inferno",
            price: 0
          },
          {
            value: 7,
            label: "Lemon Pepper",
            price: 0
          },
          {
            value: 8,
            label: "Medium",
            price: 0
          },
          {
            value: 9,
            label: "Mild",
            price: 0
          },
          {
            value: 10,
            label: "Salt, Pepper, & Garlic",
            price: 0
          }
        ]
      },
      {
        header: "Extra Dressing",
        key: 4,
        condition: true,
        list: [
          {
            value: 1,
            label: "Blue Cheese",
            price: 0
          },
          {
            value: 2,
            label: "Ranch",
            price: 0
          }
        ]
      },
      {
        header: "Extra Veggies?",
        key: 5,
        list: [
          {
            value: 1,
            label: "Yes",
            price: 0
          },
          {
            value: 2,
            label: "No",
            price: 0,
            default: true
          }
        ]
      }
    ]
  },
  {
    name: "Cinnamon Knots",
    image: require("../assets/cinnamon.jpg"),
    options: [
      {
        header: "Size",
        key: 1,
        list: [
          {
            value: 1,
            label: "1 Piece",
            price: 0.89,
            default: true
          },
          {
            value: 2,
            label: "3 Piece",
            price: 2.49
          },
          {
            value: 3,
            label: "13 Piece",
            price: 7.99
          }
        ]
      }
    ]
  }
]

function ItemDetailsScreen({ navigation, route }) {
  const { menuItem } = route.params
  const [itemPrice, setItemPrice] = React.useState(menuItem.price)

  if (Platform.OS !== "web") {
    return (
      <Screen style={{ flex: 1, backgroundColor: colors.light }}>
        <FlatList
          showsVerticalScrollIndicator={false}
          style={styles.content}
          ListHeaderComponent={
            <>
              <View style={styles.container}>
                <Image
                  style={styles.image}
                  source={{ uri: makeServerAddress(menuItem.image, "8080") }}
                />
              </View>
              <FlatList
                data={menuItem.options}
                keyExtractor={item => item.header}
                renderItem={({ item }) => (
                  <>
                    <Text style={styles.subHeader}>{item.header}</Text>
                    <List
                      style={styles.subHeader}
                      data={item.list}
                      isCheckBox={item.condition}
                      onSelectedButton={change => {
                        setItemPrice(parseFloat(itemPrice + change).toFixed(2))
                      }}
                      onSelectedBox={price =>
                        setItemPrice(parseFloat(itemPrice + price).toFixed(2))
                      }
                    />
                    <ItemSeparator
                      style={{ marginVertical: 10 }}
                      separator={item.separator}
                    />
                  </>
                )}
              ></FlatList>
              <Button
                title={"Add to Cart: $" + itemPrice}
                styleButton={{ top: 10 }}
                onPress={() => {
                  navigation.navigate("Home")
                  Toast.show("Added to Cart", {
                    containerStyle: styles.toastContainer,
                    textStyle: styles.toastText
                  })
                }}
              />
            </>
          }
        />
      </Screen>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    marginBottom: 10
  },
  content: {
    backgroundColor: colors.white,
    marginHorizontal: Platform.OS === "web" ? "30%" : 5,
    paddingHorizontal: 10
  },
  image: {
    width: "100%",
    height: 200,
    borderRadius: 10
  },
  subHeader: {
    left: 15,
    fontSize: 16,
    fontWeight: "bold"
  },
  toastContainer: {
    borderRadius: 30,
    backgroundColor: colors.black,
    width: 150
  },
  toastText: {
    fontWeight: "bold",
    fontSize: 16
  }
})

export default ItemDetailsScreen
