import React, { useState } from "react"
import { View } from "react-native"
import { CheckBox } from "react-native-elements"

import colors from "../config/colors"
import Text from "../components/Text"
import MenuContext from "../auth/menu"
import { setOption, getItemPrice, canOrder } from "../config/Options"
import { TouchableWithoutFeedback } from "react-native-gesture-handler"
import AuthContext from "../auth/context"

function AppCheckBox({
  uncheckedIcon,
  title,
  price,
  groupIndex,
  index,
  isChecked
}) {
  const { setPrice, quantity, setButtonDisabled } =
    React.useContext(MenuContext)
  const [checked, setChecked] = useState(isChecked)
  const { isSmallScreen } = React.useContext(AuthContext)

  var bkcolor = checked ? "lightgrey" : "white"
  var textcolor = colors.medium // checked ? colors.white : colors.medium

  return (
    <TouchableWithoutFeedback
      onPress={() => {
        setChecked(!checked)
        setOption(true, groupIndex, title, index, checked, price)
        setPrice(getItemPrice() * quantity)
        setButtonDisabled(!canOrder())
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: bkcolor,
          width: isSmallScreen ? null : 310,
          borderRadius: 10,
          marginLeft: isSmallScreen ? null : 5,
          marginBottom: isSmallScreen ? 3 : 5
        }}
      >
        <CheckBox
          title={title}
          containerStyle={{
            borderColor: bkcolor,
            paddingVertical: 3,
            // marginHorizontal: 0,
            backgroundColor: bkcolor
          }}
          textStyle={{
            color: textcolor
          }}
          checked={checked}
          uncheckedIcon={uncheckedIcon}
        />
        <View>
          {price !== 0 && (
            <Text
              style={{ color: textcolor, fontSize: 12, fontWeight: "bold" }}
            >
              (+${price})
            </Text>
          )}
        </View>
      </View>
    </TouchableWithoutFeedback>
  )
}

export default AppCheckBox
