import { StyleSheet } from "react-native"

import colors from "../../config/colors"

export const computeStyles = isSmallScreen =>
  StyleSheet.create({
    home: {
      color: colors.black,
      fontWeight: "500",
      textAlign: "center",
      ...(isSmallScreen && { fontSize: 14 })
    },
    homeContainer: {
      backgroundColor: colors.lightgrey,
      width: isSmallScreen ? "95%" : "85%",
      marginTop: !isSmallScreen && 10,
      paddingVertical: 10,
      borderRadius: 10,
      ...(isSmallScreen && { marginBottom: 10 })
    },
    opening: {
      color: colors.black,
      fontStyle: "normal",
      fontSize: isSmallScreen ? 16 : 20,
      lineHeight: isSmallScreen ? 35 : 70,
      textAlign: "center",
      letterSpacing: 1
    },
    openingContainer: {
      backgroundColor: colors.lightgrey,
      opacity: 0.6,
      width: 500,
      padding: 5,
      marginTop: 10,
      // marginBottom: !isSmallScreen && "12%",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      ...(isSmallScreen && {
        maxWidth: 300
      })
    }
  })
