import React, { useState, Fragment } from "react"
import { View, StyleSheet } from "react-native"

import ItemOptions from "./restaurant/ItemOptions"
import Text from "./Text"
import colors from "../config/colors"
import ItemSeparator from "./restaurant/ItemSeparator"

const List = ({ data, headerData, isCheckBox, groupIndex }) => {
  const [required, setRequired] = useState(headerData.required)

  return (
    <Fragment>
      <View style={styles.container}>
        <Text style={styles.headerContainer}>{headerData.header}</Text>
        {required && (
          <View style={styles.requiredTextContainer}>
            <Text style={styles.requiredText}>Required</Text>
          </View>
        )}
      </View>
      <ItemOptions
        data={data}
        isCheckBox={isCheckBox}
        onSelectedButton={() => setRequired(false)}
        groupIndex={groupIndex}
      />
      <ItemSeparator style={styles.separator} />
    </Fragment>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  headerContainer: {
    fontSize: 18,
    fontWeight: "bold",
    left: 15
  },
  requiredText: {
    fontSize: 13,
    textTransform: "uppercase",
    color: colors.white
  },
  requiredTextContainer: {
    backgroundColor: colors.required,
    borderRadius: 7,
    padding: 3
  },
  separator: {
    marginVertical: 10
  }
})

export default List
